// music
import music_gameOver from "./music/game-over.m4a";

// turrets
import turret_default from "./turrets/turret_default.m4a";
import turret_smg from "./turrets/turret_smg.m4a";
import turret_cannon from "./turrets/turret_cannon.m4a";
import turret_laser from "./turrets/turret_laser.m4a";
import turret_missile from "./turrets/turret_missile.m4a";
import turret_railgun from "./turrets/turret_railgun.m4a";

// collisions
import collision_ship_asteroid from "./collisions/ship+asteroid.m4a";
import collision_ship_gem from "./collisions/ship+gem.m4a";

// controls
import select_weapon from "./controls/select_weapon.m4a";

export const audioTracks = {
  // music
  "game_over": music_gameOver,
  // turrets
  turret_default,
  turret_smg,
  "turret__smg_fighter": turret_smg,
  turret_cannon,
  turret_laser,
  turret_missile,
  turret_railgun,
  // collisions
  collision_ship_gem,
  collision_ship_asteroid,
  // controls
  select_weapon,
};
