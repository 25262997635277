import React, { useState } from "react";


function ShareLink({ room }) {
  const [copied, setCopied] = useState(false);

  const roomLink = `http://admirals.io/join/${room}`;

  const copyShareLink = () => {
    navigator.clipboard.writeText(roomLink);
    setCopied(true);
  };

  const resetCopyMessage = () => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  return (
    <div className="col-sm-10 col-md-8 mx-auto share-msg">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">Share this link:</span>
        </div>
        <span className="form-control">{roomLink}</span>
        <span className="input-group-append">
          <button
            type="button"
            className={`btn ${copied ? "btn-success" : "btn-secondary"}`}
            id="copy-button"
            title="Copy to Clipboard"
            onClick={copyShareLink}
            onMouseOut={resetCopyMessage}
          >
            {copied ? "Copied!" : "Copy"}
          </button>
        </span>
      </div>
    </div>
  );
}

export default ShareLink;
