export const abilityInfo = {
  boost: {
    duration: 2,
    cooldown: 10,
    description: 'temporarily speed up the ship'
  },
  invincible: {
    duration: 5,
    cooldown: 20,
    description: 'shields up'
  },
  berserk: {
    duration: 2,
    cooldown: 10,
    description: 'deal more damage'
  },
  warp: {
    duration: 0,
    cooldown: 15,
    description: 'escape enemies at a cost'
  }
};

export const weaponInfo = {
  default: {
    rate: 2,
    magsize: 0,
    magcost: 0,
    damage: 10,
    description: "kinda weak but free",
  },
  smg: {
    rate: 6,
    magsize: 25,
    magcost: 1,
    damage: 4,
    description: "spray and pray",
  },
  cannon: {
    rate: 0.67,
    magsize: 5,
    magcost: 1,
    damage: 25,
    speed: 12,
    description: "boom as in boomer?",
  },
  laser: {
    rate: 1,
    magsize: 12,
    magcost: 1,
    damage: 20,
    speed: 40,
    description: "vaporize your enemies",
  },
  missile: {
    rate: 2.5,
    magsize: 6,
    magcost: 1,
    damage: 6,
    speed: 10,
    description: "never misses",
  },
  railgun: {
    rate: 0.2,
    magsize: 2,
    magcost: 1,
    recoil: 2.5,
    damage: 50,
    speed: 30,
    description: "pew pew",
  },
};

export const upgradeInfo = {
  boost: {
    cooldown: 0,
    duration: 0,
    description: 'temporarily speed up the ship'
  },
  warp: {
    cooldown: 0,
    description: 'escape enemies at a cost'
  },
  berserk: {
    cooldown: 0,
    duration: 0,
    description: 'deal more damage'
  },
  invincible: {
    cooldown: 0,
    duration: 0,
    description: 'shields up'
  }
};
