import React from "react";


const NotFound = () => {
  return (
    <div className="container" id="error">
      <div className="alert alert-danger" role="alert">
        <h1 className="alert-heading">404</h1>
        <p>
          <b>Error:</b> The requested page could not be found.
        </p>
        <hr />
        <p className="mb-0">
          <a href="/">Return to home</a>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
