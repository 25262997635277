import React from "react";

const HEALTH_GREEN = [134, 61, 41];
const HEALTH_RED = [-6, 70, 54];
const SPEED_VIOLET = [274, 68, 65];
const SPEED_CYAN = [186, 83, 45];
const FULL_SPEED = 10;

const hslTransition = ([h1, s1, l1], [h2, s2, l2], w) => {
  const [hd, sd, ld] = [h2 - h1, s2 - s1, l2 - l1];
  return `hsl(${h1 + w * hd}, ${s2 + w * sd}%, ${l1 + w * ld}%)`;
};

const StatsOverlay = ({ship, hull}) => {

  const health = ship.hp / ship.maxHp;
  let hpBarStyle = {
    width: 100 * health + "%",
    backgroundColor: hslTransition(HEALTH_RED, HEALTH_GREEN, health)
  };
  const hpBar = (
    <div className="progress stat-bar bg-dark">
      <div className="progress-bar" style={hpBarStyle} role="progressbar">
        {ship.hp.toFixed(0)}
      </div>
    </div>
  );

  let speedBarStyle = {
    width: 100 * hull.speed / FULL_SPEED + "%",
    backgroundColor: hslTransition(SPEED_CYAN, SPEED_VIOLET, hull.speed / FULL_SPEED)
  };
  const speedBar = (
    <div className="progress stat-bar bg-light">
      <div className="progress-bar" style={speedBarStyle} role="progressbar">
        {hull.speed.toFixed(2)}
      </div>
    </div>
  );

  return (
    <div className="overlay-block overlay-stats">
      <div className="w-100 d-flex flex-row justify-content-between">
        {hpBar}
        <span>HP</span>
      </div>
      <div className="w-100 d-flex flex-row justify-content-between">
        {speedBar}
        <span>Speed</span>
      </div>
    </div>
  );
}

export default StatsOverlay;
