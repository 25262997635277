import { Common } from "matter-js";

const part_bass = {
  length: "4m",
  progression: ["A2", "E2", "F2", "D2"],
  progress() {
    this.progression = Common.choose([
      ["A2", "E2", "F2", "D2"],
      ["A2", "B2", "C3", "D3"],
    ]);
  },
  notes: [
    {
      time: "0:0:0",
      duration: "4n",
      get note() {
        return part_bass.progression[0];
      },
      velocity: 91 / 127,
    },
    {
      time: "0:3:0",
      duration: "4n",
      get note() {
        return part_bass.progression[0];
      },
      velocity: 91 / 127,
    },
    {
      time: "1:0:0",
      duration: "4n",
      get note() {
        return part_bass.progression[1];
      },
      velocity: 91 / 127,
    },
    {
      time: "1:3:0",
      duration: "4n",
      get note() {
        return part_bass.progression[1];
      },
      velocity: 91 / 127,
    },
    {
      time: "2:0:0",
      duration: "4n",
      get note() {
        return part_bass.progression[2];
      },
      velocity: 91 / 127,
    },
    {
      time: "2:3:0",
      duration: "4n",
      get note() {
        return part_bass.progression[2];
      },
      velocity: 91 / 127,
    },
    {
      time: "3:0:0",
      duration: "4n",
      get note() {
        return part_bass.progression[3];
      },
      velocity: 91 / 127,
    },
    {
      time: "3:3:0",
      duration: "4n",
      get note() {
        return part_bass.progression[3];
      },
      velocity: 91 / 127,
    },
  ],
};

export default part_bass;
