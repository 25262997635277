import React from "react";
import UpgradePurchaseTile from "./tile_upgrade_purchase";
import WeaponPurchaseTile from "./tile_weapon_purchase";


const OverlayWarden = ({ship, setFooter}) => {
  return (
    <>
      <span>gems: {ship.gems}</span>

      <h5>Upgrades</h5>
      <div className="tile-block">

        {Object.entries(ship.upgrades).map(([name, stats]) =>
          <UpgradePurchaseTile
            key={name}
            upgradeName={name}
            level={stats.level}
            nextCost={stats.nextCost}
            disabled={stats.nextCost > ship.gems}
            setFooter={setFooter}
          />
        )}
      </div>

      <h5>Weapons</h5>
      <div className="tile-block">
        {Object.entries(ship.weapons).map(([name, stats]) =>
          <WeaponPurchaseTile
            key={name}
            weaponName={name}
            mag={stats.mag}
            gems={ship.gems}
            setFooter={setFooter}
          />
        )}
      </div>
    </>
  );
};

export default OverlayWarden;
