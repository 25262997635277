import ability_boost from "./ability_boost.png";
import ability_invincible from "./ability_invincible.png";
import ability_berserk from "./ability_berserk.png";
import ability_warp from "./ability_warp.png";

export const abilityIcons = {
  "boost": ability_boost,
  "invincible": ability_invincible,
  "berserk": ability_berserk,
  "warp": ability_warp,
};
