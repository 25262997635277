import { Vector } from "matter-js";
import MatterGame from "./matter_handler";
import socket from "./socket";
import { Queue } from "./util";
import controllers from "./controllers";
import audioContext from "./audio_handler";
import { WEAPON_TYPES } from "./skeleton";
import { SP_ADDED_COMPOSITE, SP_ADDED_BODY, SP_EXISTING_COMPOSITE, SP_EXISTING_BODY, SP_DELETED_OBJECTS } from "./schema_model";
import { NAMES_WEAPONS, NAMES_UPGRADES, NAMES_ABILITIES, NAMES_TEXTURES } from "./schema_model";
import { applyMusicEffects, playGameOver, playTracks, startMusic, stopTracks } from "./music/music_handler";

const _SUBTICKS = 5;
const VIEW_PADDING = 800;
const _SAMPLE_RANGE = 2;
const _CLIPPING_THRESHOLD = 5000;

const [ST_NONE, ST_RUNNING, ST_OVER] = [0, 1, 2];
const _N_BUFFER = 1;


class AdmiralsClient {
  constructor(appData) {
    this._lastState = ST_NONE;
    this._currentState = ST_NONE;
    this._matterGame = null;

    // external reference
    this._overlayData = appData;

    // periodic handlers
    socket.on("game_over", (data) => {
      this._over();
    });

    // enqueue updates from server and time packets
    socket.on("game_update", (data) => {
      if (this._currentState === ST_NONE) {
        return;
      }

      // lag simulation
      // setTimeout(() =>
      //   this._landUpdates.enqueue(this._decodeLandUpdate(data))
      // , Math.random()*68+2)

      this._landUpdates.enqueue(this._decodeLandUpdate(data));
      // this._landUpdates.enqueue(data);

      this._elapsedPacketTime = performance.now() - this._lastReceivedPacket;
      if (this._history.length <= _SAMPLE_RANGE * _SUBTICKS) {
        // do not allow outliers
        if (
          this._elapsedPacketTime <= 8 * this._frameLength &&
          this._elapsedPacketTime >= 0.125 * this._frameLength
        ) {
          // subtract oldest entry to maintain running sum
          if (this._history.length === _SAMPLE_RANGE * _SUBTICKS) {
            this._historySum -= this._history.dequeue();
          }

          this._history.enqueue(this._elapsedPacketTime);
          this._historySum += this._elapsedPacketTime;

          this._frameLength = 1.0005 * (this._historySum / this._history.length);
        }
      } else {
        console.log("Received Packet History length is unstable");
      }

      this._lastReceivedPacket = performance.now();
    });
  }

  // constructs initialWorld and starts game iteration
  run(initialWorld, canvas, overlayData, setOverlayData) {
    this._overlayData = overlayData;
    this._setOverlayData = setOverlayData;

    this._gameInit(initialWorld, canvas);
    this._lastState = this._currentState;
    this._currentState = ST_RUNNING;
    this._overlayData.over = false;

    // queues game updates to process from server
    this._landUpdates = new Queue();
    this._sounds = [];
    this._keyFrame = [];
    this._tick = 0;

    this._lastRenderTime = null;
    this._elapsedTime = 0;
    this._accumulatedTime = 0;

    // stores packet intervals for time smoothing
    this._history = new Queue();
    this._historySum = 0;
    this._lastReceivedPacket = null;
    this._elapsedPacketTime = 0;
    this._frameLength = 1000 / (60 / _SUBTICKS);

    window.requestAnimationFrame(this._runIteration.bind(this));
  }

  _runIteration() {
    // resubscribe immediately if game is not stopped
    if (this._currentState !== ST_NONE) {
      window.requestAnimationFrame(this._runIteration.bind(this));
    }

    if (this._isRunning()) {
      if (this._lastState !== ST_RUNNING) {
        this._runningInit();
        this._lastState = ST_RUNNING;
      }
      this._runningPeriodic();
    } else if (this._isOver()) {
      if (this._lastState !== ST_OVER) {
        this._overInit();
        this._lastState = ST_OVER;
      }
      this._overPeriodic();
    }
    this._gamePeriodic();
    this._tick += 1;
  }

  _isRunning() {
    return this._currentState === ST_RUNNING;
  }

  _isOver() {
    return this._currentState === ST_OVER;
  }

  _resume() {
    this._currentState = ST_RUNNING;
  }

  _over() {
    this._currentState = ST_OVER;
  }

  stop() {
    this._currentState = ST_NONE;
  }

  /* iterative methods */

  // runs once when game is initialized
  _gameInit(initialWorld, canvas) {
    console.log("gameInit");
    // console.log('initworld data', initialWorld)
    this._lastRenderTime = performance.now();
    this._lastReceivedPacket = performance.now();
    if (this._matterGame) {
      this._matterGame.clear();
    }
    // delete this._matterGame;
    this._matterGame = new MatterGame(canvas);

    // without schemapack
    // this._matterGame.updateLand(initialWorld);
    this._matterGame.updateLand(this._decodeLandUpdate(initialWorld));

    // create ship and hull references
    this._overlayData.ship = this._ship = this._matterGame.land.getObjectById(
      this._overlayData.shipId
    );
    this._overlayData.hull = this._hull = this._matterGame.land.getObjectById(
      this._overlayData.hullId
    );
    this._turret = this._matterGame.land.getObjectById(
      this._overlayData.turretId
    );

    controllers.initControllers(
      this._overlayData.role,
      this._matterGame.mouse,
      this._ship.weapons
    );

    // this._trackShip();
  }

  // runs whenever entering the running state
  _runningInit() {
    startMusic();
  }

  // runs whenever entering the over state
  _overInit() {
    console.log("Over init");

    stopTracks();
    // audioContext.playTrack("game_over");
    playGameOver()

    setTimeout(() => {
      this._setOverlayData({over: true});
    }, 1500)
  }

  // _testInit() {}

  // runs periodically when in any state
  _gamePeriodic() {
    this._sounds.forEach((sound) => {
      audioContext.playTrack(sound.src, sound.vol);
    });
    this._sounds.splice(0);
  }

  // runs periodically when in running state
  _runningPeriodic() {
    // update Render -> interpolate engine and land --> pause

    // update time since last render
    this._elapsedTime = performance.now() - this._lastRenderTime;

    // new: make sure elapsed time never exceeds threshold??
    // this._elapsedTime = min(this._elapsedTime, MAX_ELAPSED);
    this._accumulatedTime += this._elapsedTime;

    // fast forward to maintain a consistent input lag

    // TODO smooth out simulation speed instead of sudden pruning
    // Old Code
    // if (this._landUpdates.length > 3) {
    //   console.log('Client too far behind, pruning, length of Q:', this._landUpdates.length)
    //   while (this._landUpdates.length > 2) {
    //     this._updateLand();
    //   }
    //   this._sounds.splice(0);
    // }

    // New Code
    // Logging
    // console.log(
    //   "Tick:",
    //   this._tick,
    //   "Q-len:",
    //   this._landUpdates.length,
    //   // "Framelen:",
    //   // this._frameLength,
    //   "S Rate",
    //   1000 / this._frameLength
    // );

    // client has fallen too far behind: jump ahead to catch up
    // Clipping threshold for massive delays
    if (this._landUpdates.length > _CLIPPING_THRESHOLD / this._frameLength) {
      console.log(
        "Client too far behind, pruning, length of Q:",
        this._landUpdates.length
      );
      while (this._landUpdates.length > _N_BUFFER) {
        this._updateLand();
        this._sounds.splice(0);
      }
    }

    // speed modifier: raw addition/subtraction
    // Comments: Jumps more often but less severe with 1/3 multiplier
    // Gets messy when server is overloaded (live version with 25+ bots)
    if (this._landUpdates.length !== _N_BUFFER) {
      if (this._landUpdates.length < _N_BUFFER) {
        // client is ahead - lacking updates
        // this._accumulatedTime += (1 / 400) * (_N_BUFFER - this._landUpdates.length) * this._frameLength;
      } else {
        // client is behind - piling updates
        this._accumulatedTime += (1 / 100) * Math.pow((this._landUpdates.length - _N_BUFFER),1.25) * this._frameLength;
      }
    }

    // speed modifier: moving average sampled across three seconds

    // TODO: maintain average
    // if (this._alphaHistory.length <= (_SAMPLE_RANGE * FPS)) {
    //   // do not allow outliers
    //   if (this._elapsedTime <= 5 * this._frameLength / _SUBTICKS && this._elapsedTime >= 0.2 * this._frameLength / _SUBTICKS) {

    //     if (this._alphaHistory.length == (_SAMPLE_RANGE * FPS)) {
    //       this._alphaHistorySum -= this._alphaHistory.dequeue();
    //     }

    //     this._alphaHistory.enqueue(this._elapsedTime);
    //     this._alphaHistorySum += this._elapsedTime;

    //     this._frameLength = _SUBTICKS * (this._alphaHistorySum / this._alphaHistory.length);

    //   }
    // } else {
    //   console.log("Alpha History length is unstable")
    // }

    // perform land update when time has exceed threshold to progress one frame
    while (this._accumulatedTime >= this._frameLength) {
      // sounds pile up when browser tab becomes inactive
      if (this._accumulatedTime >= 2 * this._frameLength) {
        this._sounds.splice(0);
      }
      this._updateLand();
      this._accumulatedTime -= this._frameLength;
    }

    if (this._tick % _SUBTICKS === 0) {
      controllers.sendControls(this._overlayData.role);
      this._handleMusic();
    }

    // Debug ship location every tick
    // if (this._overlayData.hull) {
    //   console.log("tick", this._tick, this._overlayData.hull.position)
    // }

    if (this._landUpdates.length > 0) {
      // Interpolate using constant velocity assumption

      this._matterGame.interpolate(
        this._keyFrame,
        this._accumulatedTime / this._frameLength
      );

      this._updateOverlay();

      // Interpolate using constant velocity assumption without keyFrame
      // alpha shrinks as time approaches next sub-tick frame
      // Visually a little worse than regular interpolate
      // this._matterGame.interpolate_reflex(this._landUpdates.front(),  this._elapsedTime / ((1000 / (60/_SUBTICKS)) - this._accumulatedTime))
    } else {
      console.log(
        "tick",
        this._tick,
        "no frame to interpolate with, extrapolating"
      );
      // this._matterGame.updateEngine(this._accumulatedTime);
      // this._matterGame.extrapolate(this._accumulatedTime)
    }

    this._trackShip();
    this._matterGame.updateRender();

    this._lastRenderTime = performance.now();
  }

  // runs periodically when in over state
  _overPeriodic() {
    // old code
    // if (this._matterGame.land.getObjectById(this._overlayData.shipId)) {
    //   this._updateLand();
    // } else {
    //   this._matterGame.updateEngine();
    //   this._matterGame.updateRender();
    // }
    // new code
    if (this._landUpdates.length === 0) {
      this.stop();
    }
    this._runningPeriodic();
  }

  // _testPeriodic() {}

  /* additional helper methods */

  _updateLand() {
    if (this._landUpdates.length >= _N_BUFFER) {
      let landUpdate = this._landUpdates.dequeue();
      // let landUpdate = this._landUpdates.shift();
      if (landUpdate) {
        this._matterGame.updateLand(landUpdate);

        if (landUpdate.sounds) {
          landUpdate.sounds.forEach((sound) => {
            this._queueSound(sound.type, sound.pos, sound.data);
          });
        }

        // index current frame as a keyFrame for next interpolation cycle
        this._keyFrame = this._matterGame.indexFrame(
          this._landUpdates.front(),
          _SUBTICKS
        );
      }
      return true;
    } else {
      return false;
    }
  }

  _updateOverlay(data) {
    this._setOverlayData({
      ship: this._ship,
      hull: this._hull,
      ...data
    });
  }

  _decodeLandUpdate(data) {
    // let a = performance.now()
    let decoded = {
      existingComposites: data.existingComposites.map((c) =>
        SP_EXISTING_COMPOSITE.decode(c)
      ),
      existingBodies: data.existingBodies.map((b) =>
        SP_EXISTING_BODY.decode(b)
      ),
      addedComposites: data.addedComposites.map((o) =>
        SP_ADDED_COMPOSITE.decode(o)
      ),
      addedBodies: data.addedBodies.map((o) => SP_ADDED_BODY.decode(o)),
      deleted: SP_DELETED_OBJECTS.decode(data.deleted),
      sounds: data.sounds,
    };

    decoded.existingBodies.forEach((b) => {
      b.newAttributes.render.sprite.texture =
        NAMES_TEXTURES[b.newAttributes.render.sprite.texture];
      b.newAttributes.render.opacity /= 255;
    });

    const decodeComposite = (c) => {
      c.namedProperties.weapons.forEach((w) => {
        // unshift magazine size
        w.mag -= 1;
        w.name = NAMES_WEAPONS[w.sn];
        delete w.sn;
      });

      c.namedProperties.upgrades.forEach((u) => {
        u.name = NAMES_UPGRADES[u.sn];
        delete u.sn;
      });

      c.namedProperties.abilities.forEach((a) => {
        a.name = NAMES_ABILITIES[a.sn];
        delete a.sn;
      });

      c.newAttributes.currentWeapon =
        WEAPON_TYPES[c.newAttributes.currentWeapon];
    };

    decoded.existingComposites.forEach(decodeComposite);
    decoded.addedComposites.forEach(decodeComposite);

    return decoded;
  }

  _trackShip() {
    let viewPadding = {
      x: VIEW_PADDING,
      y: VIEW_PADDING,
    };
    if (this._overlayData.room === "godmode") {
      viewPadding = {
        x: 6000,
        y: 6000,
      };
    }

    // temporary for RAF
    this._matterGame._lockView(this._hull, viewPadding);
    // for matter event handler
    // this._matterGame.setViewTarget(this._hull, viewPadding);
  }

  _handleMusic() {
    // no music if not running
    if (!this._isRunning()) {
      return;
    }

    const observer = this._hull.position;

    const visibleHulls = this._matterGame.land
      .allBodies()
      .filter((b) => b.skeletonBody === "ship_hull" && b.id !== this._hull.id)
      .filter(
        (h) =>
          // Math.abs(observer.x - h.position.x) < 1.5 * VIEW_PADDING &&
          // Math.abs(observer.y - h.position.y) < 1.5 * VIEW_PADDING
          Vector.magnitude(Vector.sub(observer, h.position)) < 1.5 * VIEW_PADDING
      )
      .map((h) => h.shipType);

    const shipCount = visibleHulls.length;
    const bulletCount = this._matterGame.land
      .allBodies()
      .filter((b) => b.skeletonBody === "bullet")
      .filter((b) => Vector.magnitude(Vector.sub(observer, b.position)) < VIEW_PADDING).length;

    // console.log("visible", visibleHulls)
    applyMusicEffects({
      vibrato: 0.3 * Math.pow(1 - this._ship.hp / this._ship.maxHp, 3),
      // frequencyShift: Math.min(1, this._hull.speed / 10),
      drummerParameters: {
        shipCount,
        bulletCount,
      },
    });

    playTracks(
      {
        bass: visibleHulls.length > 0,
        pad_A: visibleHulls.length === 0,
        lead_A: visibleHulls.includes("soldier") || visibleHulls.includes("cruiser"),
        lead_B: visibleHulls.includes("fighter"),
        lead_C: visibleHulls.includes("sniper"),
        cycle_A: visibleHulls.includes("cruiser"),
        cycle_B: visibleHulls.length >= 4,
      },
      visibleHulls.includes("soldier") ||
        visibleHulls.includes("fighter") ||
        visibleHulls.includes("sniper") ||
        visibleHulls.includes("cruiser")
    );
  }

  _queueSound(type, position, sound) {
    const observer = this._hull.position;

    // inverse linear for 2D world
    let intensity = Math.min(
      1,
      300 / Vector.magnitude(Vector.sub(observer, position))
    );

    switch (type) {
      case "spawn":
        this._sounds.push({
          src: sound,
          vol: intensity,
        });
        break;
      case "death":
        // all ships
        break;
      case "hit":
        if (sound[0] === "playerShip" && sound[1] === "gem") {
          this._sounds.push({
            src: "collision_ship_gem",
            vol: intensity,
          });
        }
        if (sound[0] === "playerShip" && sound[1] === "asteroid") {
          this._sounds.push({
            src: "collision_ship_asteroid",
            vol: intensity,
          });
        }
        // ship, ammo
        // ship, bullet
        // ship, ship
        // bullet, shield
        // bullet, asteroid

        // "playerShip"
        // "sniper"
        // "soldier"
        // "cruiser"
        // "fighter"
        // "sentry"
        break;
      default:
        break;
    }
  }
}

export default AdmiralsClient;
