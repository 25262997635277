import sp from "schemapack";

import { shieldSprites, turretSprites } from "assets/sprites";

// identical to server schemas
const SP_ADDED_COMPOSITE = sp.build({
  id: "varuint",
  type: "uint8",
  newAttributes: {
    hp: "varuint",
    maxHp: "varuint",
    gems: "varuint",
    currentWeapon: "uint8",
  },
  namedProperties: {
    weapons: [{ sn: "uint8", mag: "varuint" }],
    upgrades: [{ sn: "uint8", level: "uint8", nextCost: "uint8" }],
    abilities: [{ sn: "uint8", active: "bool", timer: "uint8" }],
    // statusEffects: obj.statusEffects,
    // healing: "float32",
  },
});

const SP_ADDED_BODY = sp.build({
  id: "varuint",
  type: "uint8",
  newAttributes: {
    position: {
      x: "float32",
      y: "float32",
    },
    angle: "float32",
  },
});

const SP_EXISTING_COMPOSITE = sp.build({
  id: "varuint",
  newAttributes: {
    hp: "varuint",
    maxHp: "varuint",
    gems: "varuint",
    currentWeapon: "uint8",
  },
  namedProperties: {
    weapons: [{ sn: "uint8", mag: "varuint" }],
    upgrades: [{ sn: "uint8", level: "uint8", nextCost: "uint8" }],
    abilities: [{ sn: "uint8", active: "bool", timer: "uint8" }],
    // statusEffects: obj.statusEffects,
    // healing: "float32",
  },
});

const SP_EXISTING_BODY = sp.build({
  id: "varuint",
  newAttributes: {
    position: {
      x: "float32",
      y: "float32",
    },
    velocity: {
      x: "float32",
      y: "float32",
    },
    angle: "float32",
    angularVelocity: "float32",
    // render: "uint8"
    render: {
      // visible: "bool",
      opacity: "uint8",
      sprite: {
        texture: "uint8",
      },
    },
  },
});

const SP_DELETED_OBJECTS = sp.build(["varuint"]);


// name lookups are inverses of server serials
const NAMES_WEAPONS = {
  0: "default",
  1: "smg",
  2: "cannon",
  3: "laser",
  4: "missile",
  5: "railgun",
  6: "_smg_fighter",
  7: "_laser_sniper"
};

const NAMES_UPGRADES = {
  0: "resistance",
  1: "agility",
  2: "armor",
  3: "warp",
  4: "wrath",
  5: "flash",
  6: "hulk"
};

const NAMES_ABILITIES = {
  0: "boost",
  1: "invincible",
  2: "berserk",
  3: "warp"
};

const NAMES_TEXTURES = {
  0x00: "none",

  0x20: shieldSprites["enemy"],
  0x21: shieldSprites["sector1"],
  0x22: shieldSprites["sector2"],
  0x23: shieldSprites["sector3"],
  0x24: shieldSprites["sector4"],
  0x25: shieldSprites["sector5"],

  0x30: turretSprites["_laser_sniper"],
  0x31: turretSprites["_smg_fighter"],
  0x32: turretSprites["cannon"],
  0x33: turretSprites["default"],
  0x34: turretSprites["laser"],
  0x35: turretSprites["missile"],
  0x36: turretSprites["railgun"],
  0x37: turretSprites["smg"],

  0xFF: "."
};

// TODO: encode sound names
const NAMES_SOUNDS = {
  // turrets
  0x00: "turret_default",
  0x01: "turret_smg",
  0x02: "turret__smg_fighter",
  0x03: "turret_cannon",
  0x04: "turret_laser",
  0x05: "turret_missile",
  0x06: "turret_railgun",

  // collisions
  0x10: "collision_ship_gem",
  0x11: "collision_ship_asteroid",

  // controls
  0x21: "select_weapon",
};


export {
  SP_ADDED_COMPOSITE,
  SP_ADDED_BODY,
  SP_EXISTING_COMPOSITE,
  SP_EXISTING_BODY,
  SP_DELETED_OBJECTS,
  NAMES_WEAPONS,
  NAMES_UPGRADES,
  NAMES_ABILITIES,
  NAMES_TEXTURES
};
