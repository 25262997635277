import React, { useState } from "react";
import { unsetRole } from "services/socket";
import RolesOverlay from "./overlay_roles";
import StatsOverlay from "./overlay_stats";
import OverlayPilot from "./overlay_pilot";
import OverlayGunner from "./overlay_gunner";
import OverlayWarden from "./overlay_warden";

import "./overlay.scss";
import "./tile.scss";

const Overlay = ({overlayData}) => {

  const [footer, setFooter] = useState(
    <div className="overlay-sidebar-footer fade show" role="tooltip">
      <h6 className="card-header p-1">--</h6>
    </div>
  );

  if (!overlayData.ship) {
    return <div />
  }


  let roleOverlay;
  switch (overlayData.role) {
    case "pilot":
      roleOverlay = <OverlayPilot ship={overlayData.ship} setFooter={setFooter} />;
      break;
    case "gunner":
      roleOverlay = <OverlayGunner ship={overlayData.ship} setFooter={setFooter} />;
      break;
    case "warden":
      roleOverlay = <OverlayWarden ship={overlayData.ship} setFooter={setFooter} />;
      break;
    default:
      roleOverlay = null;
  }

  const exitArena = (e) => {
    overlayData.page = "room";
    unsetRole(overlayData.room, overlayData.username);
    overlayData.over = false;
  };

  let overMsg = <div className="over-msg" />;
  if (overlayData.over) {
    overMsg = (
      <div className="over-msg full show">
        <h1 className="display-2 text-danger drop-shadow">WRECKED</h1>
        <button type="button" onClick={exitArena} className="btn btn-secondary shadow-sm">
          Exit Game
        </button>
      </div>
    );
  }


  return (
    <div className="overlay user-select-none">
      {overMsg}

      <RolesOverlay username={overlayData.username} players={overlayData.players} roles={overlayData.roles} />

      <StatsOverlay ship={overlayData.ship} hull={overlayData.hull} role={overlayData.role} />

      <div className="overlay-block overlay-role">
        {footer}
        {roleOverlay}
      </div>

      <div className="overlay-block overlay-misc">
        <h1 className="display-4">Admirals</h1>
      </div>

    </div>
  );
};

export default Overlay;
