import bullet__laser_sniper from "./bullet__laser_sniper.png";
import bullet__smg_fighter from "./bullet__smg_fighter.png";
import bullet_default from "./bullet_default.png";
import bullet_smg from "./bullet_smg.png";
import bullet_cannon from "./bullet_cannon.png";
import bullet_laser from "./bullet_laser.png";
import bullet_missile from "./bullet_missile.png";
import bullet_railgun from "./bullet_railgun.png";

export const bulletSprites = {
  "_laser_sniper": bullet__laser_sniper,
  "_smg_fighter": bullet__smg_fighter,
  "default": bullet_default,
  "smg": bullet_smg,
  "cannon": bullet_cannon,
  "laser": bullet_laser,
  "missile": bullet_missile,
  "railgun": bullet_railgun,
};
