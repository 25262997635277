import React from "react";
import AbilitySelectTile from "./tile_ability_select";


const OverlayPilot = ({ship, setFooter}) => {
  return (
    <div className="tile-block">
      {Object.entries(ship.abilities).map(([name, stats]) =>
        <AbilitySelectTile
          key={name}
          abilityName={name}
          active={stats.active}
          timer={stats.timer}
          lastUsed={stats.lastUsed}
          setFooter={setFooter}
        />
      )}
    </div>
  );
};

export default OverlayPilot;
