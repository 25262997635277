import React from "react";
import { roleIcons } from "assets/icons";
import { classNames } from "services/util";


const RolesOverlay = ({username, players, roles}) => {
  return (
    <div className="overlay-block overlay-roles">
      {Object.entries(roles).map(([role, player]) => {
        const roleIcon = roleIcons[role];
        const current = username === player;
        const roleCardClasses = classNames({
          "role-card": true,
          "active": current
        });
        return (
          <div className={roleCardClasses} key={role}>
            <img className="drop-shadow role-card-img" src={roleIcon} alt={`${role} icon`} />
            <div className="m-1 p-1">
              <h5 className="m-0">{player || "computer"}</h5>
              <p className="m-0">{role}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RolesOverlay;
