import playerShip from "./playerShip.png";
import soldier from "./soldier.png";
import fighter from "./fighter.png";
import sniper from "./sniper.png";
import cruiser from "./cruiser.png";
import flea from "./flea.png";
import destroyer from "./destroyer.png";

export const hullSprites = {
  "playerShip": playerShip,
  "soldier": soldier,
  "fighter": fighter,
  "sniper": sniper,
  "cruiser": cruiser,
  "flea": flea,
  "destroyer": destroyer,
};
