import { Common, Composite, Events, World, Sleeping } from "matter-js";

// class objects equivalent to world/composite object
class MatterLand {
  constructor(options) {
    let world = World.create(options);

    function extend(target, source) {
      for (let prop in source) {
        if (source[prop] && source[prop].constructor === Object) {
          if (!target[prop] || target[prop].constructor === Object) {
            target[prop] = target[prop] || {};
            extend(target[prop], source[prop]);
          } else {
            target[prop] = source[prop];
          }
        } else {
          target[prop] = source[prop];
        }
      }
    }

    extend(this, world);

    // stores object hashes to lookup by id
    this._objectIdsByLabel = {};
    this._objectsById = {};

    // garbage array
    this._garbage = [];
    this._garbageIds = new Set();
  }

  // custom methods

  getObjectByLabel(label) {
    return this.getObjectById(this._objectIdsByLabel[label]);
  }

  getObjectById(id) {
    return this._objectsById[id];
  }

  removeObjectById(id, deep) {
    let object = this._objectsById[id];
    if (object !== undefined) {
      if (object.label !== "") {
        delete this._objectIdsByLabel[object.label];
      }
      delete this._objectsById[id];

      // original behavior: Composite.remove(this, object);
      this.remove(object, deep)
    }
  }

  garbage(object) {
    object.render.visible = false;
    object.collisionFilter.mask = 0;
    Sleeping.set(object, true);

    this._garbage.push(object);
    this._garbageIds.add(object.id);
    if (this._garbage.length > 100) {
      Composite.remove(this, this._garbage);
      this._garbage = [];
      this._garbageIds.clear();
    }
  }

  // generic adder for each type
  _hashObject(object) {
    if (object.label !== "") {
      this._objectIdsByLabel[object.label] = object.id;
    }
    this._objectsById[object.id] = object;
  }

  // generic remover for each type
  _unhashObject(object) {
    delete this._objectIdsByLabel[object.label];
    delete this._objectsById[object.id];
  }

  // wrapper methods based on Matter.Composite

  // setModified, not wrapped

  /**
   * Generic add function. Adds one or many body(s), constraint(s) or a composite(s)
   * Triggers `beforeAdd` and `afterAdd` events
   * @method add
   * @param {} object
   */
  add(object) {
    let objects = [].concat(object);

    Events.trigger(this, "beforeAdd", { object: object });

    for (let i = 0; i < objects.length; i++) {
      let obj = objects[i];

      switch (obj.type) {
        case "body":
          // skip adding compound parts
          if (obj.parent !== obj) {
            Common.warn(
              "MatterLand.add: skipped adding a compound body part (you must add its parent instead)"
            );
            break;
          }
          this.addBody(obj);
          break;
        case "constraint":
          this.addConstraint(obj);
          break;
        case "composite":
          this.addComposite(obj);
          break;
        case "mouseConstraint":
          this.addConstraint(obj.constraint);
          break;
      }
    }

    Events.trigger(this, "afterAdd", { object: object });
  }

  /**
   * Generic remove function. Removes one or many body(s), constraint(s) or a composite(s)
   * Optionally searching its children recursively.
   * Triggers `beforeRemove` and `afterRemove` events.
   * @method remove
   * @param {} object
   * @param {boolean} [deep=false]
   */
  remove(object, deep) {
    let objects = [].concat(object);

    Events.trigger(this, "beforeRemove", { object: object });

    for (let i = 0; i < objects.length; i++) {
      let obj = objects[i];

      switch (obj.type) {
        case "body":
          this.removeBody(obj, deep);
          break;
        case "constraint":
          this.removeConstraint(obj, deep);
          break;
        case "composite":
          this.removeComposite(obj, deep);
          break;
        case "mouseConstraint":
          this.removeConstraint(obj.constraint);
          break;
      }
    }

    Events.trigger(this, "afterRemove", { object: object });
  }

  addComposite(composite) {
    this._hashObject(composite);

    let bodies = Composite.allBodies(composite);
    bodies.forEach((body) => this._hashObject(body));

    // original behavior: Composite.addComposite(this, composite);
    // new
    this.composites.push(composite);
  }

  removeComposite(composite, deep) {
    this._unhashObject(composite);
    // original behavior
    Composite.removeComposite(this, composite, deep);
  }

  /**
   * Adds a body.
   * @private
   * @method addBody
   * @param {body} body
   */
  addBody(body) {
    this._hashObject(body);
    // original behavior: Composite.addBody(this, body);
    // fast add w/o setModified
    this.bodies.push(body);
  }

  /**
   * Removes a body, optionally searching its children recursively.
   * @private
   * @method removeBody
   * @param {body} body
   * @param {boolean} [deep=false]
   */
  removeBody(body, deep) {
    this._unhashObject(body);
    this.garbage(body);
    // original behavior
    // Composite.removeBody(this, body);
  }

  // removeBodyAt, not wrapped

  addConstraint(constraint) {
    this._hashObject(constraint);
    // original behavior
    Composite.addConstraint(this, constraint);
  }

  removeConstraint(constraint, deep) {
    this._unhashObject(constraint);
    Composite.removeConstraint(this, constraint, deep);
  }

  // removeConstraintAt, not wrapped

  /**
   * Removes all bodies, constraints and composites.
   * Optionally clearing its children recursively.
   * @method clear
   * @param {boolean} keepStatic
   * @param {boolean} [deep=false]
   */
  clear(keepStatic, deep) {
    // clear local mappings
    this._objectIdsByLabel = {};
    this._objectsById = {};

    if (keepStatic) {
      for (let body of this.bodies.filter(function (body) {
        return body.isStatic;
      })) {
        if (body.label !== "") {
          this._objectIdsByLabel[body.label] = body.id;
        }
        this._objectsById[body.id] = body;
      }
    }

    // original behavior
    Composite.clear(this, keepStatic, deep);
    if (deep) {
      for (let i = 0; i < this.composites.length; i++) {
        this.clear(this.composites[i], keepStatic, true);
      }
    }

    if (keepStatic) {
      this.bodies = this.bodies.filter(function (body) {
        return body.isStatic;
      });
    } else {
      this.bodies.length = 0;
    }

    this.constraints.length = 0;
    this.composites.length = 0;
    Composite.setModified(this, true, true, false);
  }

  // only return non-garbage bodies
  allBodies() {
    return Composite.allBodies(this).filter((body) => {
      return !this._garbageIds.has(body.id);
    });
  }

  // allConstraints, not wrapped

  // default behavior wraped
  allComposites() {
    return Composite.allComposites(this);
  }

  /**
   * Searches recursively for an object matching the type and id supplied, null if not found.
   * @method get
   * @param {number} id
   * @param {string} type
   * @return {object} The requested object, if found
   */
  get(id, type) {
    let object = this._bodiesById[id];
    return object === undefined ? null : object;
  }

  // move, not wrapped

  /**
   * Assigns new ids for all objects, recursively.
   * @method rebase
   * @param {composite} composite
   */
  rebase() {
    let objects = Composite.allBodies(this)
      .concat(Composite.allConstraints(this))
      .concat(Composite.allComposites(this));

    //      for (let i = 0; i < objects.length; i++) {
    for (let object of objects) {
      this._unhashObject(object);
      object.id = Common.nextId();
      this._hashObject(object);
    }

    Composite.setModified(this, true, true, false);
  }

  // translate, not wrapped
  // rotate, not wrapped
  // scale, not wrapped
  // bounds, not wrapped
}

export default MatterLand;
