import turret__laser_sniper from "./turret__laser_sniper.png";
import turret__smg_fighter from "./turret__smg_fighter.png";
import turret_default from "./turret_default.png";
import turret_smg from "./turret_smg.png";
import turret_cannon from "./turret_cannon.png";
import turret_laser from "./turret_laser.png";
import turret_missile from "./turret_missile.png";
import turret_railgun from "./turret_railgun.png";

export const turretSprites = {
  "_laser_sniper": turret__laser_sniper,
  "_smg_fighter": turret__smg_fighter,
  "default": turret_default,
  "smg": turret_smg,
  "cannon": turret_cannon,
  "laser": turret_laser,
  "missile": turret_missile,
  "railgun": turret_railgun,
};
