import * as Tone from "tone";

import part_bass from "./dynamic-parts/bass";
import midi_lead_A from "assets/audio/music/lead_A.json";
import midi_lead_B from "assets/audio/music/lead_B.json";
import midi_lead_C from "assets/audio/music/lead_C.json";
import midi_cycle_A from "assets/audio/music/cycle_A.json";

import music_cycle_B from "assets/audio/music/cycle_B.m4a";
import music_pad_A from "assets/audio/music/pad_A.m4a";

const synth_bass = new Tone.Synth({
  envelope: { attack: 0.05, decay: 0.8, sustain: 0.2, release: 1 },
  oscillator: {
    partials: [0.8, 0.6, 0, 0.4],
    volume: -3,
  },
}).toDestination();

const synth_lead_A = new Tone.PolySynth({
  options: {
    envelope: { attack: 0.05, decay: 0.8, sustain: 0.5, release: 0.7 },
    oscillator: {
      type: "fatsawtooth30",
      volume: -12,
    },
  },
}).toDestination();

const synth_lead_B = new Tone.Synth({
  envelope: { attack: 0.1, decay: 0.5, sustain: 0.7, release: 0.6 },
  oscillator: {
    type: "fmsquare12",
    volume: -15,
  },
}).toDestination();

const synth_lead_C = new Tone.Synth({
  envelope: { attack: 0.15, decay: 0.8, sustain: 0.9, release: 0.4 },
  oscillator: {
    type: "sawtooth20",
    volume: -9,
  },
}).toDestination();

const synth_cycle_A = new Tone.PolySynth({
  options: {
    envelope: { attack: 0.05, decay: 0.9, sustain: 0.9, release: 1.2 },
    oscillator: {
      type: "amsine4",
      volume: -9,
    },
  },
}).toDestination();

const trackPlayer = (synth, midiTrack) => {
  const part = new Tone.Part({
    callback: (time, data) => {
      synth.triggerAttackRelease(data.name || data.note, data.duration, time, data.velocity);
    },
    events: midiTrack.notes,
    loop: true,
    loopEnd: midiTrack.length,
  });
  part.start = function (time) {
    // reset synth volume
    synth.volume.value = 0;
    // call original Part start behavior
    Tone.Part.prototype.start.bind(part).call(time);
  };
  part.stop = function () {
    // fade out synth volume
    synth.volume.linearRampTo(-Infinity, part.fadeOut);
    Tone.Transport.schedule((time) => {
      // call original Part stop behavior
      return Tone.Part.prototype.stop.bind(part).call(time);
    }, part.fadeOut);
  };
  return part;
};

const player_cycle_B = new Tone.Player(music_cycle_B).toDestination();
const player_pad_A = new Tone.Player(music_pad_A).toDestination();

player_cycle_B.loop = true;
player_pad_A.loop = true;

const parts = {
  bass: trackPlayer(synth_bass, part_bass),
  lead_A: trackPlayer(synth_lead_A, midi_lead_A.tracks[0]),
  lead_B: trackPlayer(synth_lead_B, midi_lead_B.tracks[0]),
  lead_C: trackPlayer(synth_lead_C, midi_lead_C.tracks[0]),
  cycle_A: trackPlayer(synth_cycle_A, midi_cycle_A.tracks[0]),
  cycle_B: player_cycle_B,
  pad_A: player_pad_A,
};

export const progression = new Tone.Loop((time) => {
  part_bass.progress();
}, "4m");

export const setPlayerFade = () => {
  parts.bass.fadeOut = "4m";
  parts.lead_A.fadeOut = "4m";
  parts.lead_B.fadeOut = "4m";
  parts.lead_C.fadeOut = "4m";
  parts.cycle_A.fadeOut = "4m";
  player_cycle_B.fadeOut = "4m";
  player_pad_A.fadeIn = "4m";
  // player_pad_A.fadeOut = "4m";
};

export default parts;
