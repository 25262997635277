import React, { useState, useEffect } from "react";
import * as socket from "services/socket";
import Card from "./card";
import ShareLink from "./share-link";
import { resumeTone } from "services/music/music_handler";
import { classNames } from "services/util";
import diagram_pilot from "img/diagram_pilot.gif";
import diagram_gunner from "img/diagram_gunner.gif";
import diagram_warden from "img/diagram_warden.gif";

import "./room.scss";


const diagramImages = {
  pilot: diagram_pilot,
  gunner: diagram_gunner,
  warden: diagram_warden
}

const Room = ({ room, username, players, roles, ready }) => {
  const [modalProps, setModalProps] = useState({
    title: "",
    img: "",
    imgAlt: ""
  });
  const [unmountModal, setUnmountModal] = useState(false);

  const hideDiagram = () => {
    setUnmountModal(true);
  };

  useEffect(() => {
    if (unmountModal) {
      setTimeout(function() {
        setModalProps({
          title: ""
        });
        setUnmountModal(false);
      }, 150);
    }
  });

  let modalClassNames = classNames({
    "role-diagram": true,
    "fade": true,
    "show": modalProps.title && !unmountModal
  });

  let diagramModal = !modalProps.title ? (
    <div className="fade" id="diagramModal" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true"/>
  ) : (
    <div className={modalClassNames} id="diagramModal" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {modalProps.title}
            </h5>
            <button type="button" className="close" onClick={hideDiagram} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              className="img-fluid bg-light"
              src={diagramImages[modalProps.img]}
              alt={modalProps.imgAlt}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const [showReadyModal, setShowReadyModal] = useState(false);

  const signalReady = () => {
    resumeTone();
    if (players.length < 3 && !isReady) {
      setShowReadyModal(true);
    } else {
      sendReady();
    }
  };

  const sendReady = () => {
    socket.setReady(room, username);
    closeReadyModal();
  };

  const closeReadyModal = () => setShowReadyModal(false);

  const rolesSet = Object.values(players).every((player) => Object.values(roles).includes(player));
  const isReady = ready.includes(username);

  const readyButton = (
    <button
      className={"btn btn-success btn-block " + classNames({ "active": isReady })}
      onClick={signalReady}
      disabled={!rolesSet}
    >
      Ready ({ready.length}/{players.length})
    </button>
  );

  const readyModal = (
    <div
      className="modal role-diagram"
      style={{ display: showReadyModal ? "block" : "none" }}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Role not assigned a player
            </h5>
            <button type="button" className="close" onClick={closeReadyModal} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>The following roles do not have players assigned:</p>
            <ul>
              {Object.entries(roles)
                .filter(([_, player]) => !player)
                .map(([role, _]) => (
                  <li key={role}>{role}</li>
                ))}
            </ul>
            <p>
              If there is no player assigned, a computer player will fill the role for your team.
              Are you sure you want a computer player on your team?
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeReadyModal}>
              No, wait for other players
            </button>
            <button type="button" className="btn btn-primary" onClick={sendReady}>
              Yes, play with a computer
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="room" className="container-lg">
      <h2 className="text-center mb-4 text-white">
        Team <i>{room}</i>
      </h2>

      <div className="row mx-auto">
        <div className="col-sm-9">
          <div className="card-group">
            {Object.entries(roles).map(([role, player]) => (
              <Card
                key={role}
                username={username}
                room={room}
                role={role}
                player={player}
                setModalProps={setModalProps}
              />
            ))}
          </div>
        </div>

        <div className="col-sm-3">
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Players</strong>
              <span className="badge badge-primary badge-pill">
                {players.length}
              </span>
            </li>
            {players.map((player) => (
              <ListItem
                key={player}
                username={username}
                player={player}
                roles={roles}
                ready={ready}
              />
            ))}
          </ul>
          <div className="mt-2">{readyButton}</div>
        </div>
      </div>

      <ShareLink room={room} />

      {diagramModal}
      {readyModal}
    </div>
  );
};

const ListItem = ({ username, player, roles, ready }) => {
  const hasRole = Object.values(roles).some((taker) => player === taker);
  const playerReady = ready.includes(player);

  let listItemClass = classNames({
    'list-group-item text-secondary': true,
    'font-weight-bold text-dark': username === player,
    'list-group-item-success': playerReady,
    'list-group-item-warning': hasRole && !playerReady,
    'list-group-item-secondary': !hasRole && !playerReady,
  });
  return (
    <li className={listItemClass}>{player}</li>
  );
};

export default Room;
