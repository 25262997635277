import shield_enemy from "./shield_enemy.png";
import shield_sector1 from "./shield_sector1.png";
import shield_sector2 from "./shield_sector2.png";
import shield_sector3 from "./shield_sector3.png";
import shield_sector4 from "./shield_sector4.png";
import shield_sector5 from "./shield_sector5.png";

export const shieldSprites = {
  "enemy": shield_enemy,
  "sector1": shield_sector1,
  "sector2": shield_sector2,
  "sector3": shield_sector3,
  "sector4": shield_sector4,
  "sector5": shield_sector5,
};
