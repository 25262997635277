import React from "react";
import { sendControls } from "services/socket";
import audioContext from "services/audio_handler";
import { classNames } from "services/util";
import { turretIcons } from "assets/icons";
import { weaponInfo } from "./ship_info";


const WeaponSelectTile = ({weaponName, mag, shortcut, active, lastFired, cooldown, setFooter}) => {

  const rate = weaponInfo[weaponName].rate,
        damage = weaponInfo[weaponName].damage,
        disabled = mag === 0,
        description = weaponInfo[weaponName].description;

  const selectWeapon = (e) => {
    sendControls({
      controlType: "setWeapon",
      controls: {
        weapon: weaponName
      }
    });
    audioContext.playTrack("select_weapon");
  }

  const showTooltip = (e) => {
    setFooter(
      <div className="overlay-sidebar-footer fade show" role="tooltip">
        <h6 className="card-header p-1">{weaponName}</h6>
        <div className="card-body small p-1">
          <p className="m-0" style={{fontStyle: "italic"}}>{description}</p>
          <p className="m-0">Cooldown: {cooldownPercentage}</p>
          <p className="m-0">Firing Rate: {rate}</p>
          <p className="m-0">Damage: {damage}</p>
        </div>
      </div>
    );
  }

  const hideTooltip = (e) => {
    setFooter(
      <div className="overlay-sidebar-footer fade show" role="tooltip">
        <h6 className="card-header p-1">--</h6>
      </div>
    );
  }

  let cooldownPercentage = 100 * Math.min(1, (Date.now() - lastFired) / cooldown);

  if (mag < 0) {
    mag = "\u221e"    // infinity
  }

  let tileClassNames = classNames({
    "tile": true,
    "disabled": disabled,
    "selected": active
  })

  return (
    <div className="hoist" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <button type="button" className={tileClassNames} name={weaponName} onClick={selectWeapon} aria-pressed={active}>
        <img src={turretIcons[weaponName]} className="tile-img" alt="..." />
        <div className="corner-label">
          {shortcut}
        </div>
        <div className="tile-overlay">
          {mag}
        </div>
      </button>
    </div>
  )
}

export default WeaponSelectTile;
