import React, { useEffect, useState } from "react";
import {
  applyMusicEffects,
  playGameOver,
  playTracks,
  resumeTone,
  startMusic,
  stopTracks,
} from "services/music/music_handler";

function MusicTest() {
  const [selected, setSelected] = useState([]);
  const [running, setRunning] = useState(false);

  const [shipCount, setShipCount] = useState(0);
  const [bulletCount, setBulletCount] = useState(0);

  const startTest = () => {
    resumeTone();
    setSelected([]);
    setRunning(true);
    startMusic();
  };

  const stopTest = () => {
    stopTracks();
    playGameOver();
    setRunning(false);
  };

  useEffect(() => {
    playTracks(
      {
        bass: selected.length > 0,
        pad_A: selected.length === 0,
        lead_A: selected.includes("soldier") || selected.includes("cruiser"),
        lead_B: selected.includes("fighter"),
        lead_C: selected.includes("sniper"),
        cycle_A: selected.includes("cruiser"),
        cycle_B: selected.length >= 4,
      },
      selected.includes("soldier") ||
        selected.includes("fighter") ||
        selected.includes("sniper") ||
        selected.includes("cruiser")
    );
    applyMusicEffects({
      drummerParameters: {
        shipCount: Math.max(selected.length, shipCount),
        bulletCount,
      },
    });
  }, [selected, shipCount, bulletCount]);

  const handleSelect = (value) => {
    setSelected((selected) => {
      const selectedIndex = selected.indexOf(value);
      return selectedIndex === -1
        ? selected.concat(value)
        : selected.slice(0, selectedIndex).concat(selected.slice(selectedIndex + 1));
    });
  };

  const startButton = (
    <button className="btn btn-primary" onClick={startTest}>
      Start Test
    </button>
  );

  return (
    <div style={{ backgroundColor: "#fffa", padding: "4rem" }}>
      <h2>Music Test</h2>
      {!running && startButton}
      {running && (
        <div className="d-flex flex-column">
          <div className="btn-group" role="group" aria-label="Select ship types">
            {["soldier", "fighter", "sniper", "cruiser"].map((type) => (
              <React.Fragment key={type}>
                <input
                  type="checkbox"
                  id={`checkbox-${type}`}
                  className="btn-check"
                  value={selected.includes(type)}
                  onChange={() => handleSelect(type)}
                />
                <label htmlFor={`checkbox-${type}`} className="btn btn-outline-primary">
                  {type}
                </label>
              </React.Fragment>
            ))}
          </div>

          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <label htmlFor="ship-count" className="input-group-text">
                Ship Count
              </label>
            </div>
            <input
              type="number"
              id="ship-count"
              className="form-control"
              value={shipCount}
              onChange={(e) => setShipCount(e.target.value)}
            />
          </div>

          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <label htmlFor="bullet-count" className="input-group-text">
                Bullet Count
              </label>
            </div>
            <input
              type="number"
              id="bullet-count"
              className="form-control"
              value={bulletCount}
              onChange={(e) => setBulletCount(e.target.value)}
            />
          </div>

          <button className="btn btn-secondary" onClick={stopTest}>
            Stop Test
          </button>
        </div>
      )}
    </div>
  );
}

export default MusicTest;
