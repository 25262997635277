import React from "react";
import WeaponSelectTile from "./tile_weapon_select";


const OverlayGunner = ({ship, setFooter}) => {
  return (
    <div className="tile-block">
      {Object.entries(ship.weapons).map(([name, stats], index) =>
        <WeaponSelectTile
          key={name}
          weaponName={name}
          mag={stats.mag}
          shortcut={index+1}
          active={name === ship.currentWeapon}
          lastFired={stats.lastFired}
          cooldown={stats.cooldown}
          setFooter={setFooter}
        />
      )}
    </div>
  );
};

export default OverlayGunner;
