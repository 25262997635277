import React from "react";
import { sendControls } from "services/socket";
import { classNames } from "services/util";
import { upgradeIcons } from "assets/icons";


const UpgradePurchaseTile = ({upgradeName, level, nextCost, disabled, setFooter}) => {

  // const description = upgradeInfo[upgradeName].description;
  const description = "";

  const purchaseUpgrade = (e) => {
    sendControls({
      controlType: "purchaseUpgrade",
      controls: {
        upgrade: upgradeName
      }
    });
  };

  const showTooltip = (e) => {
    setFooter(
      <div className="overlay-sidebar-footer fade show" role="tooltip">
        <h6 className="card-header p-1">{upgradeName}</h6>
        <div className="card-body small p-1">
          <p className="m-0" style={{fontStyle: "italic"}}>{description}</p>
          <p className="m-0">Current Level: {level}</p>
          <p className="m-0" style={{color: disabled ? "#f55" : "5f5"}}>Upgrade Cost: {nextCost} gem(s)</p>
        </div>
      </div>
    );
  };

  const hideTooltip = (e) => {
    setFooter(
      <div className="overlay-sidebar-footer fade show" role="tooltip">
        <h6 className="card-header p-1">--</h6>
      </div>
    );
  };

  let tileClassNames = classNames({
    "tile": true,
  });

  return (
    <div className="hoist" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
    {/* mouse handlers must be outside to allow tooltips to appear even when disabled */}
      <button type="button" className={tileClassNames} name={upgradeName} onClick={purchaseUpgrade} disabled={disabled}>
        <img src={upgradeIcons[upgradeName]} className="tile-img" alt="..." />
        <div className="tile-overlay">
          {level}
        </div>
      </button>
    </div>
  );
}

export default UpgradePurchaseTile;
