import * as socket from "./socket";
import audioContext from "./audio_handler";

// should relocate global variables to class properties
let keySet = new Set();
let weapons;
let mouse;
let mousePressed = false;
let mouseReleased = false;

function initControllers(role, matterGameMouse, shipWeapons) {
  switch (role) {
    case "pilot":
      document.body.addEventListener("keydown", (e) => keySet.add(e.code));
      document.body.addEventListener("keyup", (e) => keySet.delete(e.code));
      break;
    case "gunner":
      mouse = matterGameMouse;
      mouse.element.addEventListener("mousedown", (e) => {
        mousePressed = true;
      });
      mouse.element.addEventListener("mouseup", (e) => {
        mouseReleased = true;
      });
      document.body.addEventListener("keydown", (e) => keySet.add(e.keyCode));
      weapons = shipWeapons;
      break;
    case "warden":
      // mouse control warden
      mouse = matterGameMouse;
      // document.body.addEventListener("keydown", (e) => keySet.add(e.keyCode));
      // document.body.addEventListener("keyup", (e) => keySet.delete(e.keyCode));
      break;
    default:
      break;
  }
}

function sendControls(role) {
  switch (role) {
    case "pilot":
      return _sendPilotControls();
    case "gunner":
      return _sendGunnerControls();
    case "warden":
      return _sendWardenControls();
    default:
      break;
  }
}

function _sendPilotControls() {
  let movement = {
    forward : keySet.has("KeyW"),
    left    : keySet.has("KeyA"),
    backward: keySet.has("KeyS"),
    right   : keySet.has("KeyD")
  };

  if (movement.forward || movement.backward || movement.left || movement.right)
    socket.sendControls({
      controlType: "propel",
      controls: { movement },
    });
}

function _sendGunnerControls() {
  if (mouse.absolute.x !== 0 && mouse.absolute.y !== 0) {
    socket.sendControls({
      controlType: "firearm",
      controls: {
        position: mouse.position,
        button: mousePressed ? 0 : -1,
      },
    });
  }
  if (mouseReleased) {
    mousePressed = false;
    mouseReleased = false;
  }
  // use number row to select weapon
  for (let i = 0; i < 9; ++i) {
    if (keySet.has(49 + i)) {
      socket.sendControls({
        controlType: "setWeapon",
        controls: {
          weapon: Object.keys(weapons)[i]
        }
      });
      audioContext.playTrack("select_weapon");
      keySet.clear();
      break;
    }
  }
}

function _sendWardenControls() {
  // if (movement.left || movement.right)
  //   socket.sendControls({
  //     controlType: "swivel",
  //     controls: { movement },
  //   });
  if (mouse.absolute.x !== 0 && mouse.absolute.y !== 0) {
    socket.sendControls({
      controlType: "swivel",
      controls: {
        targetPosition: mouse.position,
      },
    });
  }
}

export default { initControllers, sendControls };
