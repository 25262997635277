import React from "react";
import { sendControls } from "services/socket";
import { classNames } from "services/util";
import { turretIcons } from "assets/icons";
import { weaponInfo } from "./ship_info";


const WeaponPurchaseTile = ({weaponName, mag, gems, setFooter}) => {

  const cost = weaponInfo[weaponName].magcost,
        quantity = weaponInfo[weaponName].magsize,
        rate = weaponInfo[weaponName].rate,
        damage = weaponInfo[weaponName].damage,
        description = weaponInfo[weaponName].description;

  const disabled = cost > gems;

  const selectWeapon = (e) => {
    sendControls({
      controlType: "purchaseWeapon",
      controls: {
        weapon: weaponName
      }
    });
  };

  const showTooltip = (e) => {
    setFooter(
      <div className="overlay-sidebar-footer fade show" role="tooltip">
        <h6 className="card-header p-1">{weaponName}</h6>
        <div className="card-body small p-1">
          <p className="m-0" style={{fontStyle: "italic"}}>{description}</p>
      <p className="m-0" style={{color: disabled ? "#f55" : "5f5"}}>Cost: {cost} gem(s) for {quantity}</p>
          <p className="m-0">Firing Rate: {rate}</p>
          <p className="m-0">Damage: {damage}</p>
        </div>
      </div>
    );
  };

  const hideTooltip = (e) => {
    setFooter(
      <div className="overlay-sidebar-footer fade show" role="tooltip">
        <h6 className="card-header p-1">-</h6>
      </div>
    );
  };

  if (mag < 0) {
    mag = "\u221e"    // infinity
  }

  let tileClassNames = classNames({
    "tile": true,
  });

  return (
    <div className="hoist" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
    {/* mouse handlers must be outside to allow tooltips to appear even when button is disabled */}
      <button type="button" name={weaponName} className={tileClassNames} onClick={selectWeapon} disabled={disabled}>
        <img src={turretIcons[weaponName]} className="tile-img" alt="..." />
        <div className="tile-overlay">
          {mag}
        </div>
      </button>
    </div>
  );
}

export default WeaponPurchaseTile;
