import React, { useReducer, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, Prompt } from "react-router-dom";

import AdmiralsClient from "services/admirals_client";
import socket from "services/socket";

import { Home, Room, Arena, NotFound } from "pages";
import "./App.css";
import MusicTest from "pages/music-test/MusicTest";


const _PAGE_INITIAL = "home";

const admirals = new AdmiralsClient();


function App() {
  const [appData, setAppData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      page: _PAGE_INITIAL,
      room: "",
      username: "",
      players: [],
      roles: {
        pilot: "",
        gunner: "",
        warden: ""
      },
      ready: [],
    }
  );

  // socket listener cannot access current state of appData
  const appDataRef = useRef(appData);
  useEffect(() => {
    appDataRef.current = appData;
  }, [appData]);

  // initialize socket listeners once
  useEffect(() => {
    socket.on("game_start", (data) => {
      setAppData({ ...data.appData, initWorld: data.initWorld, page: "arena" });
      // will render Arena and start game
    });

    socket.on("room_update", (data) => {
      const appData = appDataRef.current;

      // users willingly leave arena unless team member dropped
      if (appData.page === "arena") {
        if (appData.over) {
          // ignore room update
          return;
        }
        // team member has been lost, so tell update to leave arena
        data.page = "room";
      }

      // patch app data with user information from server
      setAppData(data);
    });

    // clean up
    return socket.disconnect;
  }, []);

  const roomFull = appData.room === "full";


  return (
    <Router basename={process.env.PUBLIC_URL}>

      <Switch>
        <Route path="/music-test">
          <MusicTest />
        </Route>

        <Route
          exact path="/"
          render={(props) => {
            socket.connect();

            // if username has been set, send user to room
            if (appData.username) {
              return <Redirect push to={`/rooms/${appData.room}`} />;
            }
            // otherwise, render Home
            return <Home roomFull={roomFull} />;
          }}
        />

        <Route
          exact path="/join/:room?"
          render={(props) => {
            socket.connect();

            // if username has been set, send user to room
            if (appData.username) {
              return <Redirect push to={`/rooms/${appData.room}`} />;
            }
            // otherwise, render Home but with defaultRoom
            const defaultRoom = props.match.params.room;
            return <Home defaultRoom={defaultRoom} roomFull={roomFull} />;
          }}
        />

        <Route
          exact path="/rooms/:room"
          render={(props) => {
            // redirect to join page if no username
            if (!appData.username) {
              return <Redirect to={`/join/${props.match.params.room}`} />;
            }

            const arena = <Arena appData={appData} setAppData={setAppData} admirals={admirals} />;
            const room = <Room {...appData} />;

            // otherwise, render room or arena
            return (
              <>
                <Prompt
                  message={(location) => {
                    // when leaving room, reset socket to avoid lingering user
                    // and reset username to prevent auto-joining room
                    if (location.pathname.startsWith("/")) {
                      socket.disconnect();
                      setAppData({ username: "", page: "" });
                    }
                  }}
                />
                {appData.page === "arena" ? arena : room}
              </>
            );
          }}
        />

        <Route path="*">
          <NotFound />
        </Route>

      </Switch>
    </Router>
  );
}

export default App;
