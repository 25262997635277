import * as Tone from "tone";

import music_battle from "assets/audio/music/battle.m4a";
import music_gameOver from "assets/audio/music/game-over.m4a";
import music_overworld from "assets/audio/music/overworld.m4a";

import loop_drummer, { sampler_drummer, setDrummerParameters } from "./drummer";
import parts, { progression, setPlayerFade } from "./parts.js";

Tone.Transport.bpm.value = 120;
Tone.Transport.timeSignature = 4;

const player_gameOver = new Tone.Player(music_gameOver).toDestination();
const player_overworld = new Tone.Player(music_overworld).toDestination();
const player_battle = new Tone.Player(music_battle).toDestination();

const distortion = new Tone.Distortion({
  distortion: 0,
  wet: 0.75,
});
const vibrato = new Tone.Vibrato({
  frequency: 10,
  depth: 0,
});
// const frequencyShift = new Tone.FrequencyShifter({
//   frequency: 44,
// })

sampler_drummer.toDestination();
const gain = new Tone.Gain(0.7);
const lowpass = new Tone.Filter(12000, "lowpass");
Tone.Destination.chain(gain, distortion, vibrato, lowpass);

const activeTracks = {};

let inBattle = false;

export function playGameOver() {
  player_gameOver.start();
}

export function resumeTone() {
  Tone.start();
  stopTracks();
}

export function startMusic() {
  setPlayerFade();
  player_overworld.start("0:0", 0, 8);
  Tone.Transport.start("+0.1", "0:0");
  loop_drummer.start("4:0");
  progression.start("4:0")
}

export function applyMusicEffects(effects) {
  // distortion.distortion = effects.distortion ?? 0;
  vibrato.depth.value = effects.vibrato ?? 0;
  // frequencyShift.wet.value = effects.frequencyShift;
  setDrummerParameters(effects.drummerParameters);
}

export function stopTracks(tracks = Object.keys(parts)) {
  player_overworld.stop();
  if (player_battle.state === "started") {
    player_battle.stop();
  }
  // Tone.Transport.cancel(0);
  Tone.Transport.stop();
  loop_drummer.stop();
  progression.stop();

  tracks.forEach((track) => {
    const player = parts[track];
    // console.log("stopping", track);
    if (activeTracks[track]) {
      // console.log("cancelling", track);
      Tone.Transport.clear(activeTracks[track]);
      delete activeTracks[track];
    }
    const fadeOut = player.fadeOut;
    player.fadeOut = 0;
    player.stop();
    player.fadeOut = fadeOut;
  });
}

export function playTracks(tracks, battle) {
  // console.log("playing", tracks)
  const triggerBattle = !inBattle && battle;
  if (inBattle && !battle) {
    inBattle = false;
  }

  if (triggerBattle) {
    console.log("triggering battle");
    inBattle = true;
    // stop current tracks
    stopTracks();
    setPlayerFade();
    Tone.Transport.position = "3:0:0";
    // play battle theme and restart transport
    // player_battle.start()
    Tone.Transport.scheduleOnce((time) => {
      player_battle.start(time);
    }, "3:0");
    // player_battle.stop("+8");

    Tone.Transport.start();
    loop_drummer.start("4:0");
    progression.start("4:0");
    // tracks to play will be scheduled to bar 4
  }

  Object.entries(tracks).forEach(([track, state]) => {
    const player = parts[track];
    if (state) {
      // schedule the track if it hasn't been
      if (!activeTracks[track]) {
        // console.log("scheduling", track);
        // save the event id to use when cancelling
        activeTracks[track] = Tone.Transport.scheduleOnce((time) => {
          console.log("starting", track, time, Tone.Transport.position);
          // player.fadeOut = "4m";
          player.start(time);
        }, Tone.Transport.getSecondsAtTime(Tone.Transport.nextSubdivision("4m")));
        // }, "@4m");
      }
    } else {
      // cancel the track if it has been scheduled
      if (activeTracks[track]) {
        // console.log("cancelling", track);
        Tone.Transport.clear(activeTracks[track]);
        delete activeTracks[track];
      }
      player.stop();
    }
  });
}

export function scheduleStop(track) {
  if (!(track in parts)) {
    return;
  }
  const player = parts[track];
  player.stop();
}
