import React from "react";
import { sendControls } from "services/socket";
import { classNames } from "services/util";
import { abilityIcons } from "assets/icons";
import { abilityInfo } from "./ship_info";


const AbilitySelectTile = ({abilityName, active, timer, setFooter}) => {

  const description = abilityInfo[abilityName].description;
  const cooldown = abilityInfo[abilityName].cooldown;

  const selectAbility = (e) => {
    e.preventDefault();
    sendControls({
      controlType: "useAbility",
      controls: {
        ability: abilityName
      }
    });
  };

  const disabled = timer > 0;
  const opacity = 1 - 0.5 * timer / cooldown;

  const showTooltip = (e) => {
    setFooter(
      <div className="overlay-sidebar-footer fade show" role="tooltip">
        <h6 className="card-header p-1">{abilityName}</h6>
        <div className="card-body small p-1">
          <p className="m-0" style={{fontStyle: "italic"}}>{description}</p>
        </div>
      </div>
    );
  };

  const hideTooltip = (e) => {
    setFooter(
      <div className="overlay-sidebar-footer fade show" role="tooltip">
        <h6 className="card-header p-1">-</h6>
      </div>
    );
  };

  let tileClassNames = classNames({
    "tile": true,
    "selected": active
  });

  return (
    <div className="hoist" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
    {/* mouse handlers must be outside to allow tooltips to appear even when disabled */}
      <button type="button" className={tileClassNames} name={abilityName} onClick={selectAbility} disabled={disabled} style={{opacity: opacity}}>
        <img src={abilityIcons[abilityName]} className="tile-img" alt="..." />
        <div className="tile-overlay">
          {timer || ""}
        </div>
      </button>
    </div>
  );
}

export default AbilitySelectTile;
