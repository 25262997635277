import { Body, Engine, Events, Render, Grid } from "matter-js";
import { Mouse } from "matter-js";
import MatterLand from "./matter_land";
import { newEntity } from "./skeleton";

class MatterGame {
  constructor(canvas) {
    this._land = new MatterLand({
      label: "Land",
      gravity: {
        x: 0,
        y: 0,
        scale: 0.001,
      },
      bounds: {
        min: { x: -8000, y: -8000 },
        max: { x: 8000, y: 8000 },
      },
    });

    this._engine = Engine.create({
      world: this._land,
      // timing: {
      //   timeScale: 0.1,
      // },
      enableSleeping: true,
      positionIterations: 1,
      velocityIterations: 1,
      constraintIterations: 1,
    });

    this._render = Render.create({
      canvas: canvas,
      engine: this._engine,
      options: {
        width: canvas.width,
        height: canvas.height,
        pixelRatio: "auto",
        background: "none",
        // wireframeBackground: '#0f0f13',
        hasBounds: true,
        // enabled: true,
        wireframes: false,
        showSleeping: false,
        // showBroadphase: true,
        // showBounds: true,
        // showVelocity: true,
        // showCollisions: false,
        // showSeparations: false,
        // showAxes: true,
        // showPositions: true,
        // showAngleIndicator: true,
        // showIds: true,
        // showShadows: true
        // showVertexNumbers: true,
        // showConvexHulls: true,
        // showInternalEdges: true,
        // showMousePosition: true
      },
    });

    this._enableMouseControl(canvas);
    this._resizeWindow();
    this._enableWindowResizing();
  }

  get land() {
    return this._land;
  }

  get engine() {
    return this._engine;
  }

  get mouse() {
    return this._mouse;
  }

  setViewTarget(target, viewPadding) {
    if (target !== this._viewTarget) {
      this._lockView(target, viewPadding);
      this._viewTarget = target;
    }
  }

  _lockView(target, viewPadding) {
    // prior lookAt callbacks may need to be unsubscribed
    // temporary for RAF testing
    // Events.on(this._render, "beforeRender", () => {
    //   lookAtTarget(this._render, target, viewPadding, true);
    // });

    // Render.lookAt modified to use center of single target
    const lookAtTarget = (render, target, padding, center) => {
      center = typeof center !== "undefined" ? center : true;
      padding = padding || {
        x: 0,
        y: 0,
      };

      // find bounds of target

      let bounds = {
        min: {
          x: target.position.x,
          y: target.position.y,
        },
        max: {
          x: target.position.x,
          y: target.position.y,
        },
      };

      // originial behavior until end

      // find ratios
      var width = bounds.max.x - bounds.min.x + 2 * padding.x,
        height = bounds.max.y - bounds.min.y + 2 * padding.y,
        viewHeight = render.canvas.height,
        viewWidth = render.canvas.width,
        outerRatio = viewWidth / viewHeight,
        innerRatio = width / height,
        scaleX = 1,
        scaleY = 1;

      // find scale factor
      if (innerRatio > outerRatio) {
        scaleY = innerRatio / outerRatio;
      } else {
        scaleX = outerRatio / innerRatio;
      }

      // enable bounds
      render.options.hasBounds = true;

      // position and size
      render.bounds.min.x = bounds.min.x;
      render.bounds.max.x = bounds.min.x + width * scaleX;
      render.bounds.min.y = bounds.min.y;
      render.bounds.max.y = bounds.min.y + height * scaleY;

      // center
      if (center) {
        render.bounds.min.x += width * 0.5 - width * scaleX * 0.5;
        render.bounds.max.x += width * 0.5 - width * scaleX * 0.5;
        render.bounds.min.y += height * 0.5 - height * scaleY * 0.5;
        render.bounds.max.y += height * 0.5 - height * scaleY * 0.5;
      }

      // padding
      render.bounds.min.x -= padding.x;
      render.bounds.max.x -= padding.x;
      render.bounds.min.y -= padding.y;
      render.bounds.max.y -= padding.y;

      // update mouse
      if (render.mouse) {
        Mouse.setScale(render.mouse, {
          x: (render.bounds.max.x - render.bounds.min.x) / render.canvas.width,
          y: (render.bounds.max.y - render.bounds.min.y) / render.canvas.height,
        });

        Mouse.setOffset(render.mouse, render.bounds.min);
      }
    };

    // temporary for RAF testing
    lookAtTarget(this._render, target, viewPadding, true);
  }

  clear() {
    Render.stop(this._render);
    Engine.clear(this._engine);
    Events.off(this._render);
  }

  updateEngine(timeStep = 1000 / 60, correction) {
    Engine.update(this._engine, timeStep, correction);
  }

  runEngine() {
    Engine.run(this._engine);
  }

  updateRender() {
    Render.world(this._render);
  }

  runRender() {
    Render.run(this._render);
  }

  angleDiff(A, B) {
    return (
      Math.sign(A - B) *
      (((Math.abs(A - B) + Math.PI) % (Math.PI * 2)) - Math.PI)
    );
  }

  interpolate_reflex(nextFrame, alpha) {
    // iterate over all bodies
    if (nextFrame === undefined || nextFrame === null) {
      console.log("Interpolate_reflex: Missing Nextframe!");
      return;
    }
    nextFrame.existingBodies.forEach(({ id, newAttributes }) => {
      const body = this._land.getObjectById(id);
      if (body !== undefined) {
        Body.setPosition(body, {
          x: body.position.x + (newAttributes.position.x - body.position.x) * alpha,
          y: body.position.y + (newAttributes.position.y - body.position.y) * alpha,
        });
        Body.setAngle(body, body.angle + (this.angleDiff(newAttributes.angle, body.angle)) * alpha);
      } else {
        console.log("Interpolate_reflex: body", id, "is not defined");
      }
    });
    // find midway point of attributes to interpolate
    // apply changes to bodies/constraints/composites
    // do not modify the snapshots
  }

  interpolate(keyFrame, alpha, timeStep = 1) {
    //error handling in case of missing keyFrame
    if (keyFrame === null || keyFrame === undefined) {
      console.log("Interpolate: Missing keyFrame!");
      return;
    }
    //iterate over all existing bodies
    // keyFrame.forEach(
    // const delta = alpha * timeStep;
    //only use acceleration and initial v
    //   ({ id, a_x, a_y, v_x, v_y, ang_a, ang_v, x_i, y_i, a_i }) => {
    //     const currBody = this._land.getObjectById(id);
    //     if (currBody !== undefined) {
    //       Body.setPosition(currBody, {
    //         x: 0.5 * a_x * delta * delta + v_x * delta + x_i,
    //         y: 0.5 * a_y * delta * delta + v_y * delta + y_i,
    //       });
    //       Body.setAngle(
    //         currBody,
    //         this.angleDiff(
    //           this.angleDiff(0.5 * ang_a * delta * delta, -ang_v * delta),
    //           -a_i
    //         )
    //       );
    //     } else {
    //       console.log("Interpolate: body", id, "is not defined");
    //     }
    //   }
    // );

    //assume constant velocity
    keyFrame.forEach(({ id, x_i, y_i, ang_i, x_delta, y_delta, ang_delta }) => {
      const currBody = this._land.getObjectById(id);
      if (currBody !== undefined) {
        Body.setPosition(currBody, {
          x: x_i + x_delta * alpha,
          y: y_i + y_delta * alpha,
        });
        Body.setAngle(
          currBody,
          this.angleDiff(ang_i, -(ang_delta * alpha))
        );
      } else {
        console.log("Interpolate: body", id, "is not defined");
      }
    });
  }

  extrapolate(delta = 1) {
    //basic constant velocity assumption
    this.land.allBodies().forEach((b) => {
        Body.setPosition(b, {
          x: b.position.x + b.velocity.x* delta,
          y: b.position.y + b.velocity.y* delta,
        });
        Body.setAngle(
          b, 
          this.angleDiff(b.angle, -(b.angularVelocity * delta))
        );
    })
    return
  }

  indexFrame(endFrame, frameLength) {
    let keyFrame = [];
    if (endFrame !== null && endFrame !== undefined) {
      endFrame.existingBodies.forEach(({ id, newAttributes }) => {
        let currBody = this._land.getObjectById(id);
        //original
        // keyFrame.push({
        //   id: id,
        //   a_x: (newAttributes.velocity.x - currBody.velocity.x) / frameLength,
        //   a_y: (newAttributes.velocity.y - currBody.velocity.y) / frameLength,
        //   v_x: currBody.velocity.x,
        //   v_y: currBody.velocity.y,
        //   ang_a:
        //     (newAttributes.angularVelocity - currBody.angularVelocity) /
        //     frameLength,
        //   ang_v: currBody.angularVelocity,
        //   x_i: currBody.position.x,
        //   y_i: currBody.position.y,
        //   a_i: currBody.angle,
        // });

        // for constant velocity assumption
        const x_delta = newAttributes.position.x - currBody.position.x
        const y_delta = newAttributes.position.y - currBody.position.y
        const ang_delta = this.angleDiff(newAttributes.angle, currBody.angle)
        keyFrame.push({
          id: id,
          x_i: currBody.position.x,
          y_i: currBody.position.y,
          ang_i: currBody.angle,
          x_delta,
          y_delta,
          ang_delta,
        });
        Body.setVelocity(currBody,{
          x: x_delta/frameLength,
          y: y_delta/frameLength
        })
        Body.setAngularVelocity(currBody, ang_delta/frameLength)
      });
    }
    return keyFrame;
  }

  updateLand(data) {
    let {
      existingComposites,
      existingBodies,
      addedComposites,
      addedBodies,
      deleted,
    } = data;

    const updateComposite = (composite, newAttributes, namedProperties) => {
      Object.entries(newAttributes).forEach(([attributeName, value]) => {
        composite[attributeName] = value;
      });
      Object.entries(namedProperties).forEach(([name, propsList]) => {
        composite[name] = Object.fromEntries(
          propsList.map((props) => [props.name, props])
        );
      });
    };

    // update order should not matter

    existingComposites.forEach((b) => {
      let { id, newAttributes, namedProperties } = b;
      let existingComposite = this._land.getObjectById(id);
      if (existingComposite !== undefined) {
        updateComposite(existingComposite, newAttributes, namedProperties);
      }
    });
    existingBodies.forEach((b) => {
      let { id, newAttributes } = b;
      let existingBody = this._land.getObjectById(id);
      if (existingBody !== undefined) {
        if (newAttributes.render.sprite.texture === "none") {
          delete existingBody.render.sprite.texture;
        } else if (newAttributes.render.sprite.texture !== ".") {
          existingBody.render.sprite.texture =
            newAttributes.render.sprite.texture;
        }
        existingBody.render.opacity = newAttributes.render.opacity;
        delete newAttributes.render;
        Body.set(existingBody, newAttributes);
      }
    });

    // first team to join world receives both gameInit and gameUpdate
    // which will have the same added objects

    addedComposites.forEach((o) => {
      if (this._land.getObjectById(o.id) === undefined) {
        let { id, type, newAttributes, namedProperties } = o;
        let composite = newEntity({ id: id, type: type, ...newAttributes });
        updateComposite(composite, newAttributes, namedProperties);
        this._land.add(composite);
      } else {
        // duplicate object
      }
    });
    addedBodies.forEach((o) => {
      if (this._land.getObjectById(o.id) === undefined) {
        this._land.add(
          newEntity({ id: o.id, type: o.type, ...o.newAttributes })
        );
      } else {
        // duplicate object
      }
    });

    deleted.forEach((id) => {
      this._land.removeObjectById(id);
    });
  }

  _enableMouseControl(canvas) {
    this._mouse = Mouse.create(canvas);
    this._mouse.pixelRatio = 1; // hotfix

    // keep the mouse in sync with rendering
    this._render.mouse = this._mouse;
    this._mouse.position = { x: 0, y: 0 };
  }

  _enableWindowResizing() {
    window.addEventListener("resize", this._resizeWindow.bind(this));
  }

  _resizeWindow() {
    let vw =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    let vh =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    let canvas = this._render.canvas;
    let pixelRatio = this._render.options.pixelRatio;

    canvas.style.width = vw + "px";
    canvas.style.height = vh + "px";
    canvas.width = vw * pixelRatio;
    canvas.height = vh * pixelRatio;

    // necessary for viewport transform
    this._render.options.width = vw;
    this._render.options.height = vh;
    // bounds are handled by Render.lookAt
  }
}

export default MatterGame;
