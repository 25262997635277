import { Bodies, Common, Composite, Vertices } from "matter-js";
import { bulletSprites, hullSprites, shieldSprites, turretSprites } from "assets/sprites";
import decomp from "poly-decomp";
window.decomp = decomp;
//for client side


// TODO: consolidate type data

const entityColorPallete = {
  playerShip: [
    "#f4e1ac",
    "#c0dbe5",
    "#84cee7",
    "#c6e6f6",
    "#f4dc9c",
    "#ffffff",
  ],
  soldier: ["#76140C", "#313131", "#000000", "#931C12", "#737070"],
  fighter: ["#76140C", "#313131", "#000000", "#931C12", "#737070"],
  sniper:  ["#76140C", "#313131", "#000000", "#931C12", "#737070"],
  cruiser: ["#76140C", "#313131", "#000000", "#931C12", "#737070"],
  asteroid: [
    "#f4e1ac",
    "#c0dbe5",
    "#84cee7",
    "#c6e6f6",
    "#f4dc9c",
    "#ffffff",
  ],
};

const WEAPON_TYPES = {
  0: "default",
  1: "smg",
  2: "cannon",
  3: "laser",
  4: "missile",
  5: "railgun",
  6: "_smg_fighter",
  7: "_laser_sniper",
};

const _SHIP_TYPES = {
  0x0: "playerShip",
  0x1: "soldier",
  0x2: "fighter",
  0x3: "sniper",
  0x4: "cruiser",
  0x5: "flea",
  0x9: "asteroid"
};

const _HULL_PATHS = {
  0x0: "0 45 249 69 399 0 249 -69 0 -45",
  0x1: "0 200 367 100 0 0",
  0x2: "0 200 367 100 0 0",
  0x3: "0 208 328 104 0 0",
  0x4: "0 45 249 69 399 0 249 -69 0 -45",
  0x5: "10 10 -10 10 -10 -10 10 -10"
};

const asteroid_pallete = ["#f4e1ac", "#c0dbe5", "#84cee7", "#c6e6f6"];


const newEntity = (data) => {
  const entityType = _SKELETON_INFO[data.type >> 4];
  return entityType(
    data.id,
    0x0F & data.type,
    data.position,
    data.angle
  );
};


const Ammo = (id, subtype, position, angle) =>
  Bodies.rectangle(position.x, position.y, 15, 15, {
    mass: 10,
    id: id,
    angle: angle,
    frictionAir: 0.05,
    render: {
      sprite: {
        texture: turretSprites[WEAPON_TYPES[subtype]],
        xScale: 0.6,
        yScale: 0.6
      }
    },
    collisionFilter: { mask: 0 }
  });

const Asteroid = (id, subtype, position, angle) =>
  Bodies.polygon(position.x, position.y, 6, (0x03 & subtype) * 40 + 40, {
    mass: 1000,
    id: id,
    angle: angle,
    restitution: 0.8,
    isStatic: true,
    frictionAir: 0.005,
    render: {
      fillStyle: asteroid_pallete[subtype >> 2],
    },
    collisionFilter: { mask: 4 },
  });


const Bullet = (id, subtype, position, angle) =>
  Bodies.rectangle(position.x, position.y, 1, 1, {
    id: id,
    angle: angle,
    restitution: 1,
    frictionAir: 0.01,
    render: {
      sprite: {
        texture: bulletSprites[WEAPON_TYPES[subtype]],
      }
    },
    collisionFilter: { mask: 0 },
    skeletonBody: "bullet",
  });


const Gem = (id, subtype, position, angle) =>
  Bodies.fromVertices(
    position.x,
    position.y,
    Vertices.fromPath("-10 10 10 10 16 0 0 -16 -16 0"),
    {
      mass: 0,
      id: id,
      angle: angle,
      frictionAir: 0.02,
      collisionFilter: {
        category: 8,
        mask: 9,
      },
      render: {
        lineWidth: 3,
        strokeStyle: "#FFFFFF",
        fillStyle: (subtype & 1) === 0 ? "#FFB6C1" : "#CCFFEE",
      },
    }
  );


const ShipHull = (id, subtype, position, angle) =>
  Bodies.fromVertices(position.x, position.y, Vertices.fromPath(_HULL_PATHS[subtype]), {
    id: id,
    angle: angle || 0,
    render: {
      sprite: {
        texture: hullSprites[_SHIP_TYPES[subtype]],
        xScale: 1,
        yScale: 1,
        // soldier has slightly different turret position
        xOffset: subtype === 1 ? 0 : 0.065
      },
    },
    collisionFilter: {
      mask: 0,
    },
    skeletonBody: "ship_hull",
    shipType: _SHIP_TYPES[subtype],
  });

const ShipTurret = (id, subtype, position, angle) => {
  const scale = subtype / 8;
  return Bodies.rectangle(position.x, position.y, scale * 80, scale * 80, {
    id: id,
    angle: angle,
    mass: 0,
    frictionAir: 0.5,
    render: {
      sprite: {
        // temporary image since texture isn't set until body is updated
        texture: bulletSprites["smg"],
        xScale: scale,
        yScale: scale
      },
    },
    collisionFilter: {
      mask: 0,
    },
    entityType: "ship_turret"
  });
};

const ShipShield = (id, subtype, position, angle) =>
  Bodies.polygon(position.x, position.y, 12, 280, {
    id: id,
    angle: angle,
    friction: 1,
    frictionAir: 0,
    render: {
      sprite: {
        // xOffset: -4.3,
        texture:
          (subtype & 1) === 0
            ? shieldSprites["sector1"]
            : shieldSprites["enemy"],
      },
      fillStyle: "#FFFFFF",
      opacity: 1,
    },
    collisionFilter: {
      mask: 0,
    },
  });

const ShipFrag = (id, subtype, position, angle) => {
  // const speed = Math.random() * 5 + 5;
  // const launchAngle = Math.random() * 2 * Math.PI;
  const sides = Math.random() * 3 + 3;
  const radius = Math.random() * 10 + 20;
  const shipType = _SHIP_TYPES[subtype];
  return Bodies.polygon(position.x, position.y, sides, radius, {
    id: id,
    angle: angle,
    // mass: 30,
    // restitution: 1,
    // frictionAir: 0.02,
    // collisionFilter: {
    //   group: teamId,
    //   category: 4,
    //   mask: 11,
    // },
    render: {
      // sprite: {
      //   texture: ""
      // }
      fillStyle: Common.choose(entityColorPallete[shipType]),
    }
  });
};

const Walls = (id, subtype, position, angle) => {
  return Bodies.rectangle(position.x, position.y, 10, 10, {
    id: id,
    render: {
      fillStyle: "#432"
    }
  });
};

const ShipComposite = (id, subtype, position, angle) => {
  return Composite.create({
    id: id,
    position: position,
    weapons: {},
    upgrades: {},
    abilities: {}
  });
};


const _SKELETON_INFO = {
  0x0: Ammo,            // 5 subtypes
  0x1: Asteroid,        // 16 subtypes
  0x2: Bullet,          // 8 subtypes
  0x3: ShipHull,        // 8 subtypes
  0x4: ShipTurret,      // 8 subtypes
  0x5: ShipShield,      // 2 subtypes
  0x6: ShipFrag,        //
  0x7: Gem,             // 2 subtypes
  0xE: Walls,
  0xF: ShipComposite       //
};


export { newEntity, WEAPON_TYPES };
