import React, { useEffect, useRef } from "react";
import Overlay from "components/overlay";


const Arena = ({ appData, setAppData, admirals }) => {
  const onContextMenu = (e) => {
    e.preventDefault();
  };

  // note: canvas size will be handled by MatterGame
  const canvas = useRef();

  useEffect(() => {
    console.log("starting client");
    admirals.run(appData.initWorld, canvas.current, appData, setAppData);

    // stop admirals when leaving arena
    return admirals.stop.bind(admirals);
  }, []);   // run only once on mount

  return (
    <div className="full arena" onContextMenu={onContextMenu}>
      <canvas id="arena" ref={canvas} />
      <Overlay overlayData={appData} />
    </div>
  );
};

export default Arena;
