import React, { useState } from "react";
import { joinRoom } from "services/socket"
import { classNames } from "services/util";
import homeLogo from "img/home-logo.png";
import "./floating-labels.css";

import "./home.scss";

const versionLabel = "Beta 1.2.3";

const Home = ({ defaultRoom, roomFull }) => {
  const [username, setUsernameInput] = useState("");
  const [room, setRoomInput] = useState(defaultRoom || "");
  const [usernameValid, setUsernameValid] = useState(null);
  const [roomValid, setRoomValid] = useState(null);
  const [joinLock, setJoinLock] = useState(false);

  if (roomFull && joinLock) {
    setJoinLock(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const v_username = username !== "";
    const v_room = room !== "";
    setUsernameValid(v_username);
    setRoomValid(v_room);

    if (v_username && v_room) {
      // disable join button
      setJoinLock(true);
      joinRoom(room, username)
    }
  };

  const handleUsernameChange = (e) => {
    setUsernameInput(e.target.value);
    setUsernameValid(e.target.value !== "");
  };
  const handleRoomChange = (e) => {
    setRoomInput(e.target.value);
    setRoomValid(e.target.value !== "");
  };

  let usernameInputClass = classNames({
    'form-control': true,
    'is-invalid': usernameValid === false
  });
  let roomInputClass = classNames({
    'form-control': true,
    'is-invalid': roomValid === false
  });

  let roomFullAlert = roomFull ? (
    <div className="alert alert-danger alert-dismissible fade show" role="alert">
      <strong>The specified room is full.</strong> Please join a different room.
      </div>
  ) : null;

  let joinButton = (
    <button type="submit" className="btn btn-primary btn-block">
      Join Room
    </button>
  );
  let joiningButton = (
    <button type="submit" className="btn btn-primary btn-block" disabled>
      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
      Joining Room
    </button>
  );
  let submitButton = joinLock ? joiningButton : joinButton;


  return (
    <div id="home">
      <div className="card border-danger bg-light p-3">

        {roomFullAlert}

        <div className="text-center p-3">
          <div className="logo">
            <img className="logo-img" src={homeLogo} alt="Admirals logo" />
            <span className="logo-overlay">
              {versionLabel}
            </span>
          </div>
          <h2>Welcome to Admirals!</h2>
          <p>
            Join the same room with two other friends
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group form-label-group">
            <input type="text" id="inputUsername" className={usernameInputClass} value={username} onChange={handleUsernameChange} placeholder="username" />
            <label htmlFor="inputUsername">username</label>
            <div className="invalid-feedback">
              Please specify a username.
            </div>
          </div>

          <div className="form-group form-label-group">
            <input type="text" id="inputRoom" className={roomInputClass} value={room} onChange={handleRoomChange} placeholder="room" />
            <label htmlFor="inputRoom">room</label>
            <div className="invalid-feedback">
              Please specify a room.
            </div>
          </div>

          {submitButton}
        </form>

        <div className="mt-4 text-muted text-center">
          <span>admirals.io &copy; 2020</span>
          <br/>
          <span>Created by William Shue and Taesung Hwang</span>
        </div>
      </div>
    </div>
  );
};

export default Home;
