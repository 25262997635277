import React from "react";
import { setRole, unsetRole } from "services/socket";
import audioContext from "services/audio_handler";
import { roleIcons } from "assets/icons";


const roleDescriptions = {
  pilot: 'Pilots the ship around the world',
  gunner: 'Operates weapons on the ship',
  warden: 'Manages and protects the ship'
};
const diagramAlt = {
  pilot: 'Use the WASD (or equivalent) keys to move the ship',
  gunner: 'Point the mouse at a target and click to shoot',
  warden: 'Point the mouse to swivel the shield and block enemy bullets'
};


const Card = ({username, room, role, player, setModalProps}) => {
  let disabled = player !== "";
  let roleIcon = roleIcons[role];

  const deselectRole = (e) => {
    unsetRole(room, username);
  };
  const selectRole = (e) => {
    setRole(room, username, role);
    audioContext.resume();
  };

  let deselectButton = (
    <button type="button" className="btn btn-outline-danger btn-block"
      onClick={deselectRole}
    >
      Deselect Role
    </button>
  );
  let selectButton = (
    <button type="button" className="btn btn-outline-primary btn-block"
      onClick={selectRole} disabled={disabled}
    >
      Select Role
    </button>
  );

  const showDiagram = (e) => {
    e.preventDefault();
    setModalProps({
      title: `${role} controls`,
      img: role,
      imgAlt: diagramAlt[role]
    });
  };


  return (
    <div
      className="card border-info"
      style={{ backgroundColor: username === player ? "#bee5eb" : "#fff" }}
    >
      <img
        className="card-img drop-shadow"
        src={roleIcon}
        alt={`${role} icon`}
      />
      <div className="card-body">
        <h5 className="card-title">
          {role}
          <button
            type="button"
            className="ml-3 mb-1 btn btn-secondary btn-sm"
            onClick={showDiagram}
          >
            ?
          </button>
        </h5>
        <h6 className="card-subtitle mb-2 text-muted">
            {player || "--"}
        </h6>
        <p className="card-text">
          {roleDescriptions[role]}
        </p>
      </div>
      <div className="card-footer">
        {username === player ? deselectButton : selectButton}
      </div>
    </div>
  );
}

export default Card;
