import * as Tone from "tone";

import sample_bass from "assets/audio/samples/bass-drum.m4a";
import sample_snare from "assets/audio/samples/snare-drum.m4a";
import sample_hi_hat_A from "assets/audio/samples/hi-hat-A.m4a";
import sample_hi_hat_B from "assets/audio/samples/hi-hat-B.m4a";
import sample_hi_hat_open from "assets/audio/samples/hi-hat-open.m4a";
import sample_crash from "assets/audio/samples/crash.m4a";

export const sampler_drummer = new Tone.Sampler({
  urls: {
    C1: sample_bass,
    D1: sample_snare,
    "F#2": sample_hi_hat_A,
    "A#2": sample_hi_hat_open,
    "F#3": sample_hi_hat_B,
    "C#3": sample_crash,
  },
});

let shipCount, bulletCount;

export const setDrummerParameters = (params) => {
  shipCount = params.shipCount;
  bulletCount = params.bulletCount;
};

// play functions generate a velocity based on probability banks
// which are indexed by the current beat subdivision

const playHiHat = (n) => {
  const r = Math.random();
  if (shipCount >= 3) {
    const p = [1, 1, 1, 0.8, 1, 0.8, 0.8, 0.6, 1, 0.9, 0.8, 0.8, 1, 0.4, 1, 0.8];
    if (r < p[n]) {
      return 0.8 + 0.15 * Math.random();
    }
  } else if (shipCount >= 2) {
    const p = [1, 0, 1, 0, 1, 0, 0.75, 0, 1, 0, 0.75, 0, 1, 0, 1, 0.5];
    if (r < p[n]) {
      return 0.75 + 0.1 * Math.random();
    }
  } else if (shipCount >= 1) {
    const p = [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0.5, 0];
    if (r < p[n]) {
      return 0.7 + 0.05 * Math.random();
    }
  } else {
    const p = [1, 0, 0, 0, 0.5, 0, 0, 0, 0.75, 0, 0, 0, 0.5, 0, 0, 0];
    if (r < p[n]) {
      return 0.65;
    }
  }
  return 0;
};

const playSnare = (n) => {
  const r = Math.random();
  if (shipCount >= 3 || bulletCount >= 9) {
    const p = [0, 0, 0, 0, 1, 0, 0.5, 0, 0, 0, 0, 0.3, 1, 0, 0.6, 0.6];
    if (r < p[n]) {
      return 0.65 + 0.25 * Math.random();
    }
  } else if (shipCount >= 2 || bulletCount >= 6) {
    const p = [0, 0, 0, 0, 1, 0, 0, 0, 0, 0.4, 0, 0, 0.8, 0, 0.5, 0.5];
    if (r < p[n]) {
      return 0.6 + 0.2 * Math.random();
    }
  } else if (shipCount >= 1) {
    const p = [0, 0, 0, 0, 1, 0, 0, 0, 0, 0.2, 0, 0, 0.8, 0, 0.4, 0];
    if (r < p[n]) {
      return 0.55 + 0.15 * Math.random();
    }
  } else {
    const p = [0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0.1, 0, 0.2, 0];
    if (r < p[n]) {
      return 0.5 + 0.1 * Math.random();
    }
  }
  return 0;
};

const playBass = (n) => {
  const r = Math.random();
  if (shipCount >= 3) {
    const p = [1, 0, 0, 0, 0.5, 0, 0, 0, 0.8, 0, 0.5, 0, 0.25, 0, 0.25, 0];
    if (r < p[n]) {
      return 0.75 + 0.15 * Math.random();
    }
  } else if (shipCount >= 2) {
    const p = [1, 0, 0, 0, 0.4, 0, 0, 0, 0, 0.4, 0, 0, 0.25, 0, 0.25, 0];
    if (r < p[n]) {
      return 0.7 + 0.1 * Math.random();
    }
  } else if (shipCount >= 1) {
    const p = [1, 0, 0, 0, 0.3, 0, 0, 0, 0, 0.2, 0, 0, 0.25, 0, 0.25, 0];
    if (r < p[n]) {
      return 0.65 + 0.05 * Math.random();
    }
  } else {
    const p = [1, 0, 0, 0, 0.2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.25, 0];
    if (r < p[n]) {
      return 0.6;
    }
  }
  return 0;
};

const playCrash = (bar, n) => {
  const r = Math.random();
  if ((n === 0 && bar % 2 === 0 && r < 0.2) || bulletCount >= 10) {
    return 1;
  }
  return 0;
};

const loop_drummer = new Tone.Loop((time) => {
  const [bar, beat, sixteenth] = Tone.Transport.position.split(":");
  const n = 4 * beat + Math.round(sixteenth);
  const r = Math.random();

  const hi_hat_pitch = r < 0.1 ? "A#2" : r < 0.3 ? "F#3" : "F#2";
  sampler_drummer.triggerAttackRelease(hi_hat_pitch, "4n", time, playHiHat(n));
  sampler_drummer.triggerAttackRelease("D1", "4n", time, playSnare(n));
  sampler_drummer.triggerAttackRelease("C1", "4n", time, playBass(n));
  sampler_drummer.triggerAttackRelease("C#3", "4n", time, playCrash(bar, n));
}, "16n");

export default loop_drummer;
