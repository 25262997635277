import { io } from "socket.io-client";
const DEV_URL = "http://localhost:3001";
const socket = io(process.env.NODE_ENV === "development" ? DEV_URL : "/");

export const joinRoom = (room, username) => {
  console.log("joining room", room, "as user", username);
  socket.emit("join_room", { room, username });
};

export const unsetRole = (room, username) => {
  setRole(room, username, "");
};

export const setRole = (room, username, role) => {
  socket.emit("set_role", { room, username, role });
  console.log("setting role for user", username, "in room", room, "as", role);
};

export const setReady = (room, username) => {
  socket.emit("set_ready", { room, username });
};

export const sendControls = (data) => {
  // console.log('Emitting controls', data);
  socket.emit("controls", data);
};

export default socket;
