export const classNames = (classes) => {
  return Object.entries(classes)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
    .join(" ");
};

export const extend = (target, source) => {
  //  function extend(target, source) {
  for (let prop in source) {
    if (source[prop] && source[prop].constructor === Object) {
      if (!target[prop] || target[prop].constructor === Object) {
        target[prop] = target[prop] || {};
        extend(target[prop], source[prop]);
      } else {
        target[prop] = source[prop];
      }
    } else {
      target[prop] = source[prop];
    }
  }
};

class LN {
  constructor(value = null, next = null) {
    this.value = value;
    this.next = next;
  }
}

export class slowQ {
  constructor() {
    this._arr = [];
  }

  enqueue(value) {
    this._arr.push(value);
  }

  dequeue() {
    if (this._arr.length > 0) {
      return this._arr.shift();
    }
  }

  front() {
    if (this._arr.length > 0) {
      return this._arr[0];
    }
    return null;
  }

  get length() {
    return this._arr.length;
  }
}

export class Queue {
  constructor() {
    this.head = null;
    this.tail = null;
    this._length = 0;
  }

  enqueue(value) {
    if (this.head === null) {
      this.head = this.tail = { value: value, next: null };
    } else {
      this.tail.next = { value: value, next: null };
      this.tail = this.tail.next;
    }
    this._length += 1;
  }

  dequeue() {
    if (this.head !== null) {
      let value = this.head.value;
      this.head = this.head.next;
      if (this.head === null) {
        this.tail = null;
      }
      this._length -= 1;
      return value;
    }
    return null;
  }

  front() {
    if (this.head !== null) {
      return this.head.value;
    }
    return null;
  }

  get length() {
    return this._length;
  }
}
