import { audioTracks } from "assets/audio";

const AudioContext = window.AudioContext || window.webkitAudioContext;

const _MASTER_GAIN = 0.1;

class Audio {
  constructor() {
    this.ctx = new AudioContext();
    this._tracks = {};
    this._loadTracks();
  }

  async resume() {
    await this.ctx.resume();
    console.log("Resuming audio context");
  }

  playTrack(track, vol=1) {
    const audioBuffer = this._tracks[track];
    if (!audioBuffer) {
      return null;
    }
    const trackSource = this.ctx.createBufferSource();
    trackSource.buffer = audioBuffer;
    const gainNode = this.ctx.createGain();
    trackSource.connect(gainNode);
    gainNode.connect(this.ctx.destination);
    gainNode.gain.value = _MASTER_GAIN * vol;

    // console.log('playing track', trackSource);
    trackSource.start();
    return trackSource;
  }

  async _loadTracks() {
    for (const [track, path] of Object.entries(audioTracks)) {
      const file = await this._getFile(path, this._tracks, track);
      this._tracks[track] = file;
    }
  }

  async _getFile(filepath, target, track) {
    const response = await fetch(filepath);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.ctx.decodeAudioData(
      arrayBuffer,
      function onSuccess(decodedBuffer) {
        target[track] = decodedBuffer;
      },
      function onFailure() {
        return "failure";
      }
    );

    // for new promise based syntax
    return audioBuffer;
  }
}

const audioContext = new Audio();

export default audioContext;
