import upgrade_resistance from "./upgrade_resistance.png";
import upgrade_agility from "./upgrade_agility.png";
import upgrade_armor from "./upgrade_armor.png";
import upgrade_wrath from "./upgrade_wrath.png";
import upgrade_flash from "./upgrade_flash.png";
import upgrade_hulk from "./upgrade_hulk.png";
import upgrade_warp from "./upgrade_warp.png";

export const upgradeIcons = {
  "resistance": upgrade_resistance,
  "agility": upgrade_agility,
  "armor": upgrade_armor,
  "warp": upgrade_warp,
  "flash": upgrade_flash,
  "hulk": upgrade_hulk,
  "wrath": upgrade_wrath
};
